import {authMiddleware, SESSION_EXPIRED} from "../authMiddleware";
import {API_URL} from "../config";

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_2FA = 'UPDATE_USER_2FA';

const getUserObj = (user = {}) => {
    let mainBankAccount;
    if (user && typeof user === "object" && Array.isArray(user.bankAccounts)) {
        for (let i = 0; i < user.bankAccounts.length; i++) {
            mainBankAccount = user.bankAccounts[i];
            if (mainBankAccount && mainBankAccount.main === true) {
                break
            }
        }
    }
    return {...user, mainBankAccount}
};

export const authenticateUser = (username, authResponse) => (dispatch, getState) => {
    if (username && typeof username === "string" && authResponse && typeof authResponse === "object"
        && authResponse.hasOwnProperty("access_token")
        && authResponse.hasOwnProperty("expires_in") && authResponse.hasOwnProperty("token_type")) {

        const authenticationHeader = authResponse.token_type + ' ' + authResponse.access_token,
            state = getState(), redirectOnLoginUri = state.user.redirectOnLoginUri;

        fetch(API_URL + '/getCustomerByUsername?username=' + window.encodeURIComponent(username), {
            method: 'get',
            headers: {
                //"Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authenticationHeader
            },
        }).then(res => {
            return res.json()
        }).then(user => {
            //console.log(user);

            dispatch({
                type: AUTHENTICATE_USER,
                redirectOnLoginUri,
                user: getUserObj(user), authenticationHeader, authResponse
            })
        }).catch(err => {
            //console.log(err);

            dispatch({
                type: AUTHENTICATE_USER,
                user: null
            })
        })
    } else {
        dispatch({
            type: AUTHENTICATE_USER,
            user: null
        })
    }
};

export const reauthUser = (accessTokenParam, uidParam) => (dispatch, getState) => {
    //console.log(accessTokenParam, uidParam);
    const state = getState(),
        authenticationHeader = accessTokenParam ? "bearer " + accessTokenParam : state.user.authenticationHeader,
        uid = uidParam || state.user.uid, redirectOnLoginUri = state.user.redirectOnLoginUri;

    if (authenticationHeader && uid) {
        fetch(API_URL + '/customer/' + encodeURIComponent(uid), {
            method: 'get',
            headers: {
                //"Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authenticationHeader
            },
        }).then(res => {
            //console.log(res);
            return res.json()
        }).then(user => {
            dispatch({
                type: AUTHENTICATE_USER,
                redirectOnLoginUri,
                user: getUserObj(user), authenticationHeader
            })
        }).catch(err => {
            dispatch({
                type: AUTHENTICATE_USER,
                redirectOnLoginUri: window.location.pathname,
                user: null
            })
        })
    } else {
        dispatch({
            type: AUTHENTICATE_USER,
            redirectOnLoginUri: window.location.pathname,
            user: null
        })
    }
};

export const updateUserLanguage = (lang = "sl") => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    if (authenticationHeader && user && user.hasOwnProperty("username")) {
        fetch(API_URL + '/updateCustomer/' + user.Id, {
            method: 'put',
            headers: {
                //"Content-Type": "application/json",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authenticationHeader
            },
            body: JSON.stringify({
                "Id": user.Id,
                "username": user.username,
                "settings": {
                    "language": lang.toUpperCase()
                }
            })
        }).then(authMiddleware(dispatch)).then(res => {
            return res.json()
        }).then(user => {
            dispatch({
                type: UPDATE_USER,
                user: getUserObj(user)
            })
        }).catch(err => {
            console.log(err);


        })
    } else {

    }
};

export const updateUserNotifications = (key, value) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    if (authenticationHeader && user && user.hasOwnProperty("username")) {
        fetch(API_URL + '/updateCustomer/' + user.Id, {
            method: 'put',
            headers: {
                //"Content-Type": "application/json",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authenticationHeader
            },
            body: JSON.stringify({
                "Id": user.Id,
                "username": user.username,
                "settings": {
                    "language": user.settings.language,
                    "notifications": {
                        ...user.settings.notifications,
                        [key]: value
                    }
                }
            })
        }).then(authMiddleware(dispatch)).then(res => {
            return res.json()
        }).then(user => {
            dispatch({
                type: UPDATE_USER,
                user: getUserObj(user)
            })
        }).catch(err => {
            console.log(err);


        })
    } else {

    }
};

export const updateUserInvestorProfile = (key, value, cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    if (authenticationHeader && user && user.hasOwnProperty("username")) {
        const investorProfileKey = user.investorType === "Business" ? "companyInvestorProfile" : "investorProfile";
        let body;

        if (key === "taxNo") {
            body = JSON.stringify({
                "Id": user.Id,
                "username": user.username,
                "taxNo": value,
            })
        } else {
            body = JSON.stringify({
                "Id": user.Id,
                "username": user.username,
                [investorProfileKey]: {
                    ...user[investorProfileKey] || {},
                    [key]: value
                }
            })
        }

        fetch(API_URL + '/updateCustomer/' + user.Id, {
            method: 'put',
            headers: {
                //"Content-Type": "application/json",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authenticationHeader
            },
            body
        }).then(authMiddleware(dispatch)).then(res => {
            return res.json()
        }).then(user => {
            if (cb) {
                cb()
            }
            dispatch({
                type: UPDATE_USER,
                user: getUserObj(user)
            })
        }).catch(err => {
            if (cb) {
                cb()
            }
            console.error(err);
        })
    } else {

    }
};

export const uploadDocuments = (formData, cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/uploadDocuments/' + userId, {
        method: 'post',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId
        },
        body: formData
    }).then(authMiddleware(dispatch)).then(res => {
        if (res.status === 200) {
            if (cb) {
                cb(true)
            }
        } else {
            if (cb) {
                cb(false)
            }
        }
    }).catch(error => {
        if (cb) {
            cb(false)
        }
        console.error(error);
    })
};

export const updateUserTaxNo = (taxNo) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    if (authenticationHeader && user && user.hasOwnProperty("username")) {
        fetch(API_URL + '/updateCustomer/' + user.Id, {
            method: 'put',
            headers: {
                //"Content-Type": "application/json",
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authenticationHeader
            },
            body: JSON.stringify({
                "Id": user.Id,
                "username": user.username,
                taxNo
            })
        }).then(authMiddleware(dispatch)).then(res => {
            return res.json()
        }).then(user => {
            dispatch({
                type: UPDATE_USER,
                user: getUserObj(user)
            })
        }).catch(err => {
            console.log(err);


        })
    } else {

    }
};

export const changePasswordOld = (cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    fetch(API_URL + '/changePassword/' + user.Id, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "Content-Type": "application/json"
        }
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(data => {
        if (cb) {
            cb(data)
        }

        /*dispatch({
            type: UPDATE_USER,
            user
        })*/
    }).catch(err => {
        console.error(err);
        if (cb) {
            cb(false)
        }
    })
};

export const changePassword = (cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    fetch(API_URL + '/tokenRequest/' + user.Id, {
        method: 'post',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "tokenType": 'CHANGE_PASSWORD'
        })
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(data => {
        if (cb) {
            cb(data)
        }

        /*dispatch({
            type: UPDATE_USER,
            user
        })*/
    }).catch(err => {
        console.error(err);
        if (cb) {
            cb(false)
        }
    })
};

export const lostPassword = (cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    fetch(API_URL + '/lostPassword?username=' + user.username, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        }
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(data => {
        console.log(data);

        if (cb) {
            cb()
        }

        /*dispatch({
            type: UPDATE_USER,
            user
        })*/
    }).catch(err => {
        console.error(err);
        if (cb) {
            cb(false)
        }
    })
};

export const requestSmsToken = (tokenType, isResend, cb) => (dispatch, getState) => {
  const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

  if (authenticationHeader && user && user.hasOwnProperty("username")) {
      fetch(API_URL + '/tokenRequest/' + user.Id, {
          method: 'POST',
          headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "tokenType": tokenType,
            "isResend": isResend || false,
          })
      }).then(authMiddleware(dispatch)).then(res => {
          return res.json()
      }).then(data => {
        console.log(data, 'Odgovor strežnika');
        if (cb) {
          cb(data)
        }

        // Id: "5ebe48dc352df30001bd4eda"
        // verified: false
      }).catch(err => {
          console.log(err);
      })
  } else {

  }
};

export const enableTwoFactorAuth = (verificationId, smsCode) => (dispatch, getState) => {
  const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

  if (authenticationHeader && user && user.hasOwnProperty("username")) {
      fetch(API_URL + '/twoFactorAuth/' + user.Id, {
          method: 'put',
          headers: {
              "Accept": "application/json",
              "Authorization": authenticationHeader,
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "verificationId": verificationId,
            "code": smsCode,
          })
      }).then(authMiddleware(dispatch)).then(res => {
          if(res.status === 200) {
              dispatch({
                  type: UPDATE_USER_2FA,
                  twoFactorAuthEnabled: true
              })

          }
      }).catch(err => {
          console.log(err);
      })
  } else {

  }
};

export const disableTwoFactorAuth = (verificationId, smsCode) => (dispatch, getState) => {
  const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

  if (authenticationHeader && user && user.hasOwnProperty("username")) {
      fetch(API_URL + '/twoFactorAuth/' + user.Id, {
          method: 'delete',
          headers: {
              "Accept": "application/json",
              "Authorization": authenticationHeader,
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "verificationId": verificationId,
            "code": smsCode,
          })
      }).then(authMiddleware(dispatch)).then(res => {
          if(res.status === 200) {
              dispatch({
                  type: UPDATE_USER_2FA,
                  twoFactorAuthEnabled: false
              })
          }
      }).catch(err => {
          console.log(err);
      })
  } else {

  }
};

export const deleteAccount = (verificationId, smsCode) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, user = state.user.currentUser;

    if (authenticationHeader && user && user.hasOwnProperty("username")) {
        fetch(API_URL + '/deleteAccount/' + user.Id, {
            method: 'post',
            headers: {
                "Accept": "application/json",
                "Accept-Language": user.settings.language.toLowerCase(),
                "Authorization": authenticationHeader,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "verificationId": verificationId,
                "code": smsCode,
            })
        }).then(authMiddleware(dispatch)).then(res => {
            if (res.status !== 200) {
                res.json().then(t => window.alert(t.message));
                return;
            }

            dispatch({
                type: SESSION_EXPIRED
            })
        }).catch(err => {
            console.log(err);
        })
    } else {

    }
};
