import React, {Component} from 'react';
import {Trans, withTranslation} from 'react-i18next'
import '../styles/register.scss';
import facebookImage from '../images/Facebook.svg';
import instagramImage from '../images/Instagram.svg';
import linkedInImage from '../images/Linkedin.svg';
import {BrowserRouter as Router, Link, Redirect, Route, Switch, withRouter} from "react-router-dom";
import * as ReactDOM from "react-dom";
import ScrollToTop from "../components/ScrollToTop";
import Script from 'react-load-script';
import countries from '../locales/countries'
import $ from 'jquery'
import '../libs/selectric/jquery.selectric';
import '../libs/air-datepicker/js/datepicker';
import '../libs/air-datepicker/js/i18n/datepicker.en';

import {connect} from "react-redux";
import {authenticateUser} from "../actions/user";
import i18next from "i18next";
import AppResetPassword from "./AppResetPassword";
import AppLostPassword from "./AppLostPassword";
import AppSentConfirmation from "./AppSentConfirmation";
import {API_URL} from "../config";
import Analytics from "../components/Analytics";
import Terms from "../components/Terms";

$.fn.datepicker.language['en'] = {
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'mm/dd/yyyy',
    timeFormat: 'hh:ii aa',
    firstDay: 0
};

$.fn.datepicker.language['sl'] = {
    days: ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'],
    daysShort: ['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob'],
    daysMin: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
    months: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
    today: 'Danes',
    clear: 'Počisti',
    dateFormat: 'mm/dd/yyyy',
    timeFormat: 'hh:ii aa',
    firstDay: 1
};

const mapStateToProps = state => {
    return {_currentUser: state.user.currentUser};
};

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateUser: (username, authResponse) => dispatch(authenticateUser(username, authResponse))
    };
};

class NotFound extends Component {
    render() {
        return (
            <div>
                <div className="Register-Content-Header">
                    <Trans i18nKey='notFoundHeader'>
                        <h1 className="Register-Heading">404.</h1>
                    </Trans>
                    <Trans i18nKey='notFoundSubheader'>
                        <span className="Register-Subtitle">Page you are looking for can't be found.</span>
                    </Trans>
                    <div className="Register-Content-Body">
                        <Link to="/" className="Button">
                            <Trans i18nKey='backToHomepage'>Back to homepage</Trans>
                        </Link>
                    </div>
                </div>
                <RegisterConnect/>
                <RegisterLoginInstead/>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterHeader extends Component {
    render() {
        return (
            <div className="Register-Header-Wrapper">
                <div className="Register-Header Container Container--Stretch">
                    <Link to="/" className="Register-Header-Logo"/>
                    <div className="Register-Content-Steps Register-Content-Steps--Active">
                        <div id="steps" className="Register-Content-Step"/>
                    </div>
                    <div className="Register-Header-Menu">
                        {/*<Trans i18nKey='loginLink'>
                            <Link to="/" className="Register-Header-Menu-Item">Login</Link>
                        </Trans>*/}
                        <div className="Register-Header-Menu-Item">
                            <select ref="languageChooser" defaultValue={i18next.language}>
                                <option value="EN">English</option>
                                <option value="SL">Slovenščina</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this);
        if (node instanceof HTMLElement) {
            const child = node.querySelector('#steps');
            window.updateStep = (step) => {
                if (step) {
                    child.setAttribute('data-step', step || '');
                } else {
                    child.removeAttribute('data-step')
                }
            }
        }

        const $el = $(this.refs.languageChooser).selectric({
            onChange: e => {
                const lang = $el.val();
                i18next.changeLanguage(lang, (err, t) => {
                    if (err) return console.log('something went wrong loading', err);
                    t('key'); // -> same as i18next.t
                });
            }
        });

        // language chooser mobile
        this.refs.languageChooser.addEventListener('change', () => {
            const lang = this.refs.languageChooser.value;
            i18next.changeLanguage(lang, (err, t) => {
                if (err) return console.log('something went wrong loading', err);
                t('key'); // -> same as i18next.t
            });
        })
    }
}

class RegisterDesktopSteps extends Component {
    render() {
        const _steps = [
            {
                t: window.trans("Identity"),
                h: () => {
                    return steps["account-type"].href
                }
            },
            {
                t: window.trans("Contact"),
                h: () => {
                    return steps["personal"]["email"].href
                }
            },
            {
                t: window.trans("Security"),
                h: () => {
                    return steps["choose-password"].href
                }
            },
            {
                t: window.trans("Legal"),
                h: () => {
                    return steps[this.props.userData.value.accountType]["agreements"].href
                }
            },
        ];
        _steps.forEach((s, i) => {
            if (this.props.step === i) {
                s.c = " Register-Desktop-Steps-Item--Active";
            } else if (this.props.userData.completed >= i) {
                s.c = " Register-Desktop-Steps-Item--Completed";
                s._h = s.h();
            }
        });

        return (
            <div className="Register-Desktop-Steps-Container">
                <ul className="Register-Desktop-Steps">
                    {_steps.map((step, i) => (
                        <li key={i} className={"Register-Desktop-Steps-Item" + (step.c || "")}>
                            {step._h ? (<Link to={step._h}>{step.t}</Link>) : step.t}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

class RegisterContent extends Component {
    render(a) {
        return (
            <div className="Register-Content">
                {this.props.children}
            </div>
        );
    }
}

class RegisterConnect extends Component {
    render() {
        return (
            <div className="Register-Connect-Wrapper Hide-On-Desktop">
                <div className="Register-Connect Container">
                    <div className="Register-Connect-Now">
                        <Trans i18nKey='connectNowTitle'>
                            <h4 className="Register-Connect-Now-Heading">Connect now</h4>
                        </Trans>
                        <Trans i18nKey='connectNowSubtitle'>
                            <span className="Register-Connect-Now-Text">Visit us on social networks</span>
                        </Trans>
                    </div>
                    <div className="Register-Connect-Icons">
                        <a className="Register-Connect-Icon" href="#a"><img src={linkedInImage} alt="" width="20"/></a>
                        <a className="Register-Connect-Icon" href="#b"><img src={instagramImage} alt="" width="20"/></a>
                        <a className="Register-Connect-Icon" href="#c"><img src={facebookImage} alt="" width="20"/></a>
                    </div>
                </div>
            </div>
        );
    }
}

class RegisterLoginInstead extends Component {
    render() {
        return (
            <div className="Register-LoginInstead Hide-On-Desktop">
                <Trans i18nKey='loginInsteadText'>
                    <span className="Register-LoginInstead-Text">Already have an account?</span>
                </Trans>
                <Trans i18nKey='loginInsteadLink'>
                    <Link to="/" className="Register-LoginInstead-Link">Sign in here!</Link>
                </Trans>
            </div>
        );
    }
}

class RegisterInstead extends Component {
    render() {
        return (
            <div className="Register-LoginInstead">
                <Trans i18nKey='registerInsteadText'>
                    <span className="Register-LoginInstead-Text">Don't have an account?</span>
                </Trans>
                <Trans i18nKey='registerInsteadLink'>
                    <Link to="/register" className="Register-LoginInstead-Link">It's time to create one! <b>Sign up</b></Link>
                </Trans>
            </div>
        );
    }
}

class RegisterFooter extends Component {
    render() {
        return (
            <div className="Register-Footer-Wrapper">
                <div className="Register-Footer Container">
                    <span>All rights reserved © Nekster d.o.o. 2020</span>
                </div>
            </div>
        );
    }
}

class Login extends Component {
    state = {};

    handleSubmit(e) {
        e.preventDefault();

        const username = this.refs.username.value, password = this.refs.password.value, data = new URLSearchParams();

        data.append("grant_type", "password");
        data.append("scope", "read+write");
        data.append("username", username);
        data.append("password", password);

        this.setState({fetching: true});

        fetch(API_URL + "/oauth/token", {
            method: 'post',
            mode: "cors",
            cache: "no-cache",
            headers: {
                //"Content-Type": "application/json",
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic d2VidWk6d2VidWlzZWNyZXQ="
            },
            body: data,
        }).then(res => {
            return res.json();
        }).then(res => {
            //console.log(res);

            if (res && typeof res === "object") {
                if (res.hasOwnProperty("error")) {
                    this.setState({error: window.trans(res.error), fetching: false});
                } else if (res.hasOwnProperty("access_token")) {
                    this.setState({fetching: false});
                    this.props.authenticateUser(username, res);
                } else {
                    this.setState({error: window.trans("error-logging-in"), fetching: false});
                }
            } else {
                this.setState({error: window.trans("error-logging-in"), fetching: false});
            }

        }).catch(err => {
            this.setState({error: window.trans("error-logging-in"), fetching: false});
            console.error(err)
        });
    }

    render() {
        // TODO: fetching
        const {fetching, error} = this.state, {t} = this.props;
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="Register-Content-Header">
                    <Trans i18nKey='loginTitle'>
                        <h1 className="Register-Heading">Log in to your account<b/></h1>
                    </Trans>
                    <div className="Register-Content-Body">
                        <div className="Register-Row">
                            <div className="Register-FormField">
                                <input ref="username" type="text" name="username" className="Register-Input"
                                       placeholder={window.trans("usernamePlaceholder")}
                                       autoComplete="email"/>
                            </div>
                        </div>
                        <div className="Register-Row">
                            <div className="Register-FormField Register-FormField--Next">
                                <input ref="password" type="password" name="password" className="Register-Input"
                                       placeholder={window.trans("passwordPlaceholder")}
                                       autoComplete="current-password"/>

                                <button disabled={fetching} type="submit" data-loading={fetching}
                                        className="Button Button--Next">
                                    <div className="Button__Loader"/>
                                </button>
                            </div>
                            <span className="Register-FormField-Helper Register-FormField-Helper--Error">{error || t(this.props.error)}</span>
                        </div>
                        <div className="Register-Row">

                        </div>
                        <Trans i18nKey='resetPasswordHere'>
                            <Link className="Register-Subtitle" to={"/lost-password"}>Did you forget your
                                password?</Link>
                        </Trans>
                    </div>
                </div>
                <RegisterConnect/>
                <RegisterInstead/>
            </form>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterStep1 extends Component {
    render() {
        return (
            <div>
                <div className="Register-Content-Header">
                    <Trans i18nKey='registerTitle'>
                        <h1 className="Register-Heading">Smart <b>investing</b> starts <b>here</b>.</h1>
                    </Trans>
                    <Trans i18nKey='registerSubtitle'>
                        <span className="Register-Subtitle">Get your investor account in under 2 min</span>
                    </Trans>
                    <div className="Register-Content-Body">
                        <Link to="/register/account-type" className="Button">
                            <Trans i18nKey='startRegistration'>Start registration</Trans>
                        </Link>
                    </div>
                </div>
                <RegisterConnect/>
                <RegisterLoginInstead/>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterStep2 extends Component {
    handlePersonalClick(e) {
        this.goNext(this.props.onChange("personal"))
    }

    handleBusinessClick(e) {
        this.goNext(this.props.onChange("business"))
    }

    goNext(valid) {
        this.props.onForward()
    }

    render() {
        return (
            <div>
                <div>
                    <div className="Register-Desktop-Steps-Wrapper">
                        <div className="Register-Desktop-Steps-Content">
                            <div className="Register-Content-Header">
                                <Trans i18nKey='accountType'>
                                    <h1 className="Register-Heading2">What kind of account would you like to
                                        open<b>?</b>
                                    </h1>
                                </Trans>
                            </div>
                            <div className="Register-Content-Body Register-Content-Body--Horizontal">
                                <button className="Button" onClick={this.handlePersonalClick.bind(this)}>
                                    <Trans i18nKey='personalAccount'>Personal account</Trans>
                                </button>
                                <div className="Register-Content-Spacer"/>
                                <div className="Register-Content-Spacer"/>
                                <button className="Button" onClick={this.handleBusinessClick.bind(this)}>
                                    <Trans i18nKey='businessAccount'>Business account</Trans>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <RegisterConnect/>
                <RegisterLoginInstead/>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

const countryList = Object.keys(countries).map((key, i) => {
    const c = countries[key];
    return Object.assign({text: c.name.toUpperCase()}, c)
});

class RegisterPersonalCountry extends Component {
    constructor(props) {
        super(props);
        const value = props.value || {}, valid = props.valid || false;
        this.state = {value: value, stringValue: value.name || '', valid, datalist: countryList};
    }

    handleChange(e) {
        const stringValue = e.target.value;

        const filterOptions = countryList.filter(
            d => stringValue === "" || d.text.includes(stringValue.toUpperCase())
        );

        this.refs.datalist.classList.add('Register-Datalist--Active');

        let value;
        if (filterOptions.length === 1 && filterOptions[0].text === stringValue.toUpperCase()) {
            value = filterOptions[0];
            //this.refs.selectCountry.value = value.name;
        }

        const [valid, error] = this.props.onChange(value);
        this.setState({value, datalist: filterOptions, valid, error});
    }

    handleSelect(e) {
        if (e.target.nodeName.toLocaleLowerCase() === "li") {
            const code = e.target.getAttribute("data-code");

            if (countries.hasOwnProperty(code)) {
                const value = countries[code];
                this.refs.selectCountry.value = value.name;
                this.refs.datalist.classList.remove('Register-Datalist--Active');

                const [valid, error] = this.props.onChange(value);
                this.setState({value, datalist: countryList, valid, error});
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid, value} = this.state;

        if (valid) {
            this.setState({fetching: true});

            fetch(API_URL + '/isRegionValid?regionOrState=' + encodeURIComponent(value.name), {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                },
            }).then(res => {
                return res.json();
            }).then(json => {
                if (typeof json === "boolean") {
                    // ok

                    const verifiedValue = Object.assign({valid: json}, value);

                    const [valid, error] = this.props.onChange(verifiedValue);
                    this.setState({value: verifiedValue, valid, error, fetching: false});

                    this.props.onForward()
                }
            }).catch(err => {
                this.setState({error: window.trans("something-wrong"), fetching: false});
                console.error(err)
            });

        }
    }

    render() {
        const {stringValue, valid, datalist, fetching} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={0} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>

                        <div className="Register-Content-Header">
                            <Trans i18nKey='countryOfCitizenship'>
                                <h1 className="Register-Heading2">What is your country of citizenship<b>?</b></h1>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Next">
                                    <div ref="datalist" className="Register-Datalist">
                                        <input type="text" className="Register-Datalist-Input Register-Input"
                                               ref="selectCountry" name="country"
                                            /*autoComplete="country-name"*/
                                               placeholder={window.trans("countryPlaceholder")}
                                               autoComplete="off"
                                               onChange={this.handleChange.bind(this)}
                                               defaultValue={stringValue || ''}/>
                                        <ul className="Register-Datalist-Dropdown"
                                            onClick={this.handleSelect.bind(this)} aria-hidden="true">
                                            {datalist.map(c => (
                                                <li key={c.code} data-code={c.code}>{c.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <button className="Register-Select-Icon"/>
                                    <button disabled={!valid || fetching} hidden={!valid} type="submit"
                                            data-loading={fetching}
                                            className="Button Button--Next">
                                        <div className="Button__Loader"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('0');

        this.refs.selectCountry.addEventListener('click', () => {
            this.refs.datalist.classList.toggle('Register-Datalist--Active');
        })
    }
}

class RegisterBusinessCountry extends Component {
    constructor(props) {
        super(props);
        const value = props.value || {}, valid = props.valid || false;
        this.state = {value: value, stringValue: value.name || '', valid, datalist: countryList};
    }

    handleChange(e) {
        const stringValue = e.target.value;

        const filterOptions = countryList.filter(
            d => stringValue === "" || d.text.includes(stringValue.toUpperCase())
        );

        this.refs.datalist.classList.add('Register-Datalist--Active');

        let value;
        if (filterOptions.length === 1 && filterOptions[0].text === stringValue.toUpperCase()) {
            value = filterOptions[0];
            //this.refs.selectCountry.value = value.name;
        }

        const [valid, error] = this.props.onChange(value);
        this.setState({value, datalist: filterOptions, valid, error});
    }

    handleSelect(e) {
        if (e.target.nodeName.toLocaleLowerCase() === "li") {
            const code = e.target.getAttribute("data-code");

            if (countries.hasOwnProperty(code)) {
                const value = countries[code];
                this.refs.selectCountry.value = value.name;
                this.refs.datalist.classList.remove('Register-Datalist--Active');

                const [valid, error] = this.props.onChange(value);
                this.setState({value, datalist: countryList, valid, error});
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid, value} = this.state;

        if (valid) {
            this.setState({fetching: true});

            fetch(API_URL + '/isRegionValid?regionOrState=' + encodeURIComponent(value.name), {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                },
            }).then(res => {
                return res.json();
            }).then(json => {
                if (typeof json === "boolean") {
                    // ok

                    const verifiedValue = Object.assign({valid: json}, value);

                    const [valid, error] = this.props.onChange(verifiedValue);
                    this.setState({value: verifiedValue, valid, error, fetching: false});

                    this.props.onForward()
                }
            }).catch(err => {
                this.setState({error: window.trans("something-wrong"), fetching: false});
                console.error(err)
            });

        }
    }

    render() {
        const {stringValue, valid, datalist, fetching} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={0} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>

                        <div className="Register-Content-Header">
                            <Trans i18nKey='companyCountry'>
                                <h1 className="Register-Heading2">In which country is your company based<b>?</b>
                                </h1>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Next">
                                    <div ref="datalist" className="Register-Datalist">
                                        <input type="text" className="Register-Datalist-Input Register-Input"
                                               ref="selectCountry" name="country"
                                            /*autoComplete="country-name"*/
                                               placeholder={window.trans("countryPlaceholder")}
                                               autoComplete="off"
                                               onChange={this.handleChange.bind(this)}
                                               defaultValue={stringValue || ''}/>
                                        <ul className="Register-Datalist-Dropdown"
                                            onClick={this.handleSelect.bind(this)} aria-hidden="true">
                                            {datalist.map(c => (
                                                <li key={c.code} data-code={c.code}>{c.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <button className="Register-Select-Icon"/>
                                    <button disabled={!valid || fetching} hidden={!valid} type="submit"
                                            data-loading={fetching}
                                            className="Button Button--Next">
                                        <div className="Button__Loader"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('0');

        this.refs.selectCountry.addEventListener('click', () => {
            this.refs.datalist.classList.toggle('Register-Datalist--Active');
        })
    }
}

class RegisterBusinessCountryNotSupported extends Component {
    constructor(props) {
        super(props);
        this.state = {fetching: false};
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({fetching: true});
        setTimeout(() => {
            this.setState({fetching: false});
        }, 1000)
    }

    render() {
        const {fetching, error} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <div className="Register-Desktop-Steps-Content">
                    <div>
                        <div className="Register-Content-Header">
                            <Trans i18nKey='countryNotSupportedHeading1'>
                                <h1 className="Register-Heading">Sorry, we don't support your <b>country</b> yet.</h1>
                            </Trans>
                            <Trans i18nKey='countryNotSupportedHeading2'>
                                <b className="Register-Subtitle">We do not offer our services for companies in your
                                    country. Yet.</b>
                            </Trans>
                            <Trans i18nKey='countryNotSupportedHeading3'>
                                <span className="Register-Subtitle">Leave us an email and we will let you know as soon as we start operation in your country.</span>
                            </Trans>
                        </div>
                        <form onSubmit={this.handleSubmit.bind(this)}
                              className="Register-Content-Body Register-Content-Body--With-Action">
                            <div className="Register-Row">
                                <div className="Register-FormField">
                                    <input type="email" className="Register-Input" name="email"
                                           placeholder={window.trans("placeholderEmail")}
                                           autoComplete="email"/>
                                    <span
                                        className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                                </div>
                            </div>
                            <button disabled={fetching} type="submit" className="Button Button--Wide Register-Action"
                                    data-loading={fetching}>
                                <span className="Button__Label">
                                    <Trans i18nKey='getNotified'>Get notified</Trans>
                                </span>
                                <span className="Button__Loader"/>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterBusinessLegalRepresentativeError extends Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value || '', valid: props.valid || false};
    }

    render() {
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <div className="Register-Desktop-Steps-Content">
                    <div>
                        <div className="Register-Content-Header">
                            <Trans i18nKey='legalRepresentativeErrorHeading1'>
                                <h1 className="Register-Heading">Sorry, you must be a legal <b>representative</b>.</h1>
                            </Trans>
                            <Trans i18nKey='legalRepresentativeErrorHeading2'>
                                <span className="Register-Subtitle">Only legal representatives can open an account on behalf of the company.</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body Register-Content-Body--With-Action">
                            <Link to="/">
                                <button className="Button Button--Wide Register-Action">
                                    <Trans i18nKey='backToHomepage'>Back to homepage</Trans>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterBusinessVat extends Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value || '', valid: props.valid || false};
    }

    handleChange(e) {
        const value = e.target.value, [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();

        const {valid, value} = this.state;

        if (valid) {
            this.setState({fetching: true, error: ''});

            fetch(API_URL + '/companies?tax_no=' + encodeURIComponent(value), {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
            }).then(res => {
                return res.json();
            }).then(res => {

                const companyData = {};

                if (typeof res === "object") {
                    companyData["name"] = res.name;
                    companyData["taxNo"] = res.taxNo;

                    if (res.hasOwnProperty("address")) {
                        companyData["address"] = res.address["streetAndNumber"];
                        companyData["city"] = res.address["city"];
                    }
                }

                this.props.data.company = companyData;

                const [valid] = this.props.onChange(res.taxNo);

                this.setState({
                    error: valid ? '' : window.trans('something-wrong'),
                    fetching: false,
                    value: res.taxNo,
                    valid: valid
                });

                if (valid) {
                    this.props.onForward();
                }
            }).catch(err => {
                this.setState({error: window.trans('company-vat-not-found'), fetching: false});
                console.error(err)
            });
        }
    }

    render() {
        const {value, valid, error, fetching} = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>

                <div className="Register-Content-Header">
                    <Trans i18nKey='companyVat'>
                        <h1 className="Register-Heading2">What is your company VAT registration number<b>?</b></h1>
                    </Trans>
                </div>
                <div className="Register-Content-Body">
                    <div className="Register-Row">
                        <div className="Register-FormField">
                            <input ref="vatNumber" type="text" className="Register-Input" name="vat"
                                   defaultValue={value || ""}
                                   onChange={this.handleChange.bind(this)}
                                   placeholder={window.trans("vatPlaceholder")}/>
                        </div>
                    </div>
                    <div className="Register-Row">
                        <span className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                    </div>
                    <button disabled={!valid || fetching} type="submit" className="Button Button--Wide Register-Action"
                            data-loading={fetching}>
                                <span className="Button__Label">
                                    <Trans i18nKey='confirm'>I confirm</Trans>
                                </span>
                        <span className="Button__Loader"/>
                    </button>
                </div>
                <RegisterConnect/>
                <RegisterLoginInstead/>
            </form>

        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterBusinessCompany extends Component {
    constructor(props) {
        super(props);
        const value = typeof props.value === "object" ? props.value : {};
        this.state = {value: value, valid: props.valid || false};
    }

    handleChange(e) {
        const {value} = this.state;

        let pointer = value;
        e.target.name.split('.').forEach((name, i, arr) => {
            if (i === arr.length - 1) {
                // last
                pointer[name] = e.target.value
            } else {
                if (!pointer.hasOwnProperty(name)) {
                    pointer[name] = {};
                }
                pointer = pointer[name]
            }
        });


        const [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid} = this.state;

        if (valid) {
            this.props.onForward()
        }
    }

    render() {
        const {value, valid} = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="Register-Content-Header">
                    <Trans i18nKey='confirmCompanyData'>
                        <h1 className="Register-Heading2">Is your company data correct<b>?</b></h1>
                    </Trans>
                </div>
                <div className="Register-Content-Body Register-Content-Body--With-Action">
                    <div className="Register-Row">
                        <div className="Register-FormField">
                            <input type="text" className="Register-Input" name="company.name"
                                   defaultValue={value.name || ""}
                                   onChange={this.handleChange.bind(this)} placeholder="Company name"
                                   autoComplete="organization"/>
                            <Trans i18nKey='helperCompanyName'>
                                <span className="Register-FormField-Helper">Company name</span>
                            </Trans>
                        </div>
                    </div>
                    <div className="Register-Row">
                        <div className="Register-FormField">
                            <input type="text" className="Register-Input" name="company.address"
                                   defaultValue={value.address || ""}
                                   onChange={this.handleChange.bind(this)}
                                   placeholder="e.g. Ljubljanska ulica 12"
                                   autoComplete="street-address"/>
                            <Trans i18nKey='helperStreetAddress'>
                                <span className="Register-FormField-Helper">Street address</span>
                            </Trans>
                        </div>
                    </div>
                    <div className="Register-Row">
                        <div className="Register-FormField">
                            <input type="text" className="Register-Input" name="company.city"
                                   defaultValue={value.city || ""}
                                   onChange={this.handleChange.bind(this)}
                                   placeholder="e.g. 1000 Ljubljana"/>
                            <Trans i18nKey='helperCity'>
                                <span className="Register-FormField-Helper">City</span>
                            </Trans>
                        </div>
                    </div>

                    <button hidden={!valid} type="submit" className="Button Button--Wide Register-Action">
                        <Trans i18nKey='confirm'>I confirm</Trans>
                    </button>
                </div>
            </form>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterBusinessLegal extends Component {
    constructor(props) {
        super(props);
        //this.state = {value: typeof props.value === "object" ? props.value : {}, valid: props.valid || false};

        this.state = {value: props.value || '', valid: props.valid || false};
    }

    handleChange(e) {
        const value = e.target.value, [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();
        /*const {valid} = this.state;

        if (valid) {

        }*/
        this.props.onForward()
    }

    render() {
        const {value} = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="Register-Content-Header">
                    <Trans i18nKey='legalRepresentative'>
                        <h1 className="Register-Heading2">Are you a legal representative of the company<b>?</b></h1>
                    </Trans>
                </div>
                <div className="Register-Content-Body Register-Content-Body--With-Action">
                    <div className="Register-Content-Spacer"/>
                    <div className="Register-Row">
                        <div className="Register-FormField Register-FormField--Horizontal">
                            <label className="Register-Checkbox">
                                <input name="businessLegal" onChange={this.handleChange.bind(this)}
                                       checked={value === "on"}
                                       type="radio" defaultValue="on"/>
                                <div className="Register-Checkbox-Icon"/>
                            </label>
                            <span className="Register-Checkbox-Text"><Trans i18nKey='YES'>YES</Trans></span>
                        </div>
                    </div>
                    <div className="Register-Row">
                        <div className="Register-FormField Register-FormField--Horizontal">
                            <label className="Register-Checkbox">
                                <input name="businessLegal" onChange={this.handleChange.bind(this)}
                                       type="radio" defaultValue="off"/>
                                <div className="Register-Checkbox-Icon"/>
                            </label>
                            <span className="Register-Checkbox-Text"><Trans i18nKey='NO'>NO</Trans></span>
                        </div>
                    </div>

                    <div className="Register-Content-Spacer"/>
                    <button type="submit" className="Button Button--Wide Register-Action">
                        <Trans i18nKey='confirm'>I confirm</Trans>
                    </button>
                </div>
            </form>

        );
    }

    componentDidMount() {
        //this.el = ReactDOM.findDOMNode(this);
        window.updateStep(0);
    }
}

class RegisterPersonalFirstName extends Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value || '', valid: props.valid || false};
    }

    handleChange(e) {
        const value = e.target.value, [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid} = this.state;

        if (valid) {
            this.props.onForward()
        }
    }

    render() {
        const {value, valid} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={0} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Register-Content-Header">
                            <Trans i18nKey='headingFirstName'>
                                <h1 className="Register-Heading2">What is your first name<b>?</b></h1>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Next">
                                    <input type="text" className="Register-Input" name="firstName"
                                           defaultValue={value || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderFirstName")}
                                           autoComplete="given-name"/>
                                    <button disabled={!valid} hidden={!valid} type="submit"
                                            className="Button Button--Next"/>
                                </div>
                            </div>
                        </div>

                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('1');
    }
}

class RegisterPersonalLastName extends Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value || '', valid: props.valid || false};
        console.log("data", props.data);
    }

    handleChange(e) {
        const value = e.target.value, [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid} = this.state;

        if (valid) {
            this.props.onForward()
        }
    }

    render() {
        const {value, valid} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={0} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingLastName'>What is your last name,</Trans>
                                <b> {this.props.data.personalFirstName}?</b>
                            </h1>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Next">
                                    <input type="text" className="Register-Input" name="lastName"
                                           defaultValue={value || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderLastName")}
                                           autoComplete="family-name"/>
                                    <button disabled={!valid} hidden={!valid} type="submit"
                                            className="Button Button--Next"/>
                                </div>
                            </div>
                        </div>
                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('2');
    }
}

class RegisterPersonalDateOfBirth extends Component {
    constructor(props) {
        super(props);

        const value = typeof props.value === "object" ? props.value : null;

        this.state = {
            value: value,
            valid: props.valid || false,
            bd: props.data,
            error: window.trans(props.error)
        };

        // TODO: display error
    }

    handleChange(e) {
        let {value} = this.state;
        value = value && typeof value === "object" ? value : {};

        const stringValue = e.target.value, name = e.target.name;

        switch (name) {
            case "dayOfMonth":
                value["dayOfMonth"] = stringValue;
                if (stringValue.length === 2) {
                    this.refs.dateMonth.focus();
                }
                break;
            case "month":
                value["month"] = stringValue;
                if (stringValue.length === 2) {
                    this.refs.dateYear.focus();
                }
                break;
            case "year":
                value["year"] = stringValue;
                break;
        }

        const [valid, err] = this.props.onChange(value);
        this.setState({value: value, valid: valid, error: window.trans(err)});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid} = this.state;

        if (valid) {
            this.props.onForward()
        }
    }

    render() {
        const {error, valid} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={0} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingDateOfBirth'>When were you born,</Trans>
                                <b> {this.props.data.personalFirstName}?</b>
                            </h1>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Next Register-FormField--Date"
                                     data-error={!!error}>
                                    <input type="text" data-language={i18next.language || 'en'}
                                           className="Register-FormField__Datepicker"
                                           ref="datepicker" aria-hidden="true"/>
                                    <input type="text" ref="dateDay"
                                           className="Register-Input Register-Input-PX-Input" name="dayOfMonth"
                                           onChange={this.handleChange.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"
                                           placeholder={window.trans("placeholderDateDay")}/>
                                    <input type="text" ref="dateMonth"
                                           className="Register-Input" name="month"
                                           onChange={this.handleChange.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"
                                           placeholder={window.trans("placeholderDateMonth")}/>
                                    <input type="text" ref="dateYear"
                                           className="Register-Input" name="year"
                                           onChange={this.handleChange.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"
                                           placeholder={window.trans("placeholderDateYear")}/>
                                    <span ref="showDatepicker" className="Register-Input-PX Register-Input-PX--Date"/>
                                    <button disabled={!valid} hidden={!valid} type="submit"
                                            className="Button Button--Next"/>
                                    <span
                                        className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                                </div>
                            </div>
                        </div>
                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('3');
        const {value} = this.state;

        // Datepicker

        this.$el = $(this.refs.datepicker);
        this.$el.datepicker({
            onSelect: (fd, d, picker) => {
                const dayString = d.getDate().toString(), monthString = (d.getMonth() + 1).toString(),
                    dayOfMonth = dayString.length === 1 ? '0' + dayString : dayString,
                    month = monthString.length === 1 ? '0' + monthString : monthString,
                    year = d.getFullYear().toString();

                this.refs.dateDay.value = dayOfMonth;
                this.refs.dateMonth.value = month;
                this.refs.dateYear.value = year;

                const value = {dayOfMonth, month, year};

                const [valid, err] = this.props.onChange(value);
                this.setState({value: value, valid: valid, error: window.trans(err)});
            }
        });
        const picker = this.$el.datepicker().data('datepicker');

        if (!!value && typeof value === "object" && value.hasOwnProperty("date") && value.date instanceof Date) {
            picker.selectDate(value.date);
        }

        this.refs.showDatepicker.addEventListener('click', () => {
            picker.show();
        })
    }
}

class RegisterPersonalEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value || '', valid: props.valid || false};
    }

    handleChange(e) {
        const value = e.target.value, [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid, error: err});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid, value} = this.state;

        if (valid) {
            this.setState({fetching: true});

            fetch(API_URL + '/existsCustomerByUsername?username=' + encodeURIComponent(value), {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                },
            }).then(res => {
                return res.json();
            }).then(json => {
                if (typeof json === "boolean") {
                    // ok
                    if (json) {
                        // email already exists
                        this.setState({error: window.trans("email-taken"), fetching: false});
                    } else {
                        this.setState({error: null, fetching: false});
                        this.props.onForward()
                    }
                }
            }).catch(err => {
                this.setState({error: window.trans("something-wrong"), fetching: false});
                console.error(err)
            });

            //this.props.onForward()
        }
    }

    render() {
        const {value, valid, fetching, error} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={1} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingEmail'>What is your email address,</Trans>
                                <b> {this.props.data.personalFirstName}?</b>
                            </h1>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Next" data-error={!!error}>
                                    <input type="email" className="Register-Input" name="email"
                                           defaultValue={value || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderEmail")}
                                           autoComplete="email"/>
                                    <button disabled={!valid || fetching} hidden={!valid} type="submit"
                                            data-loading={fetching}
                                            className="Button Button--Next">
                                        <div className="Button__Loader"/>
                                    </button>
                                    <span
                                        className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                                </div>
                            </div>
                        </div>
                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('4');
    }
}

class RegisterPersonalAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {value: typeof props.value === "object" ? props.value : {}, valid: props.valid || false};
    }

    handleChange(e) {
        const {value} = this.state;

        value[e.target.name] = e.target.value;

        const [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid} = this.state;

        if (valid) {
            this.props.onForward()
        }
    }

    render() {
        const {value, valid} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={1} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingAddress'>What is your address,</Trans>
                                <b> {this.props.data.personalFirstName}?</b>
                            </h1>
                            <Trans i18nKey='subtitleAddress'>
                                <span className="Register-Subtitle">We need this data to verify your deposits</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row Register-Row--3-1">
                                <div className="Register-FormField">
                                    <input type="text" className="Register-Input" name="streetName"
                                           defaultValue={value.streetName || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderStreetName")}
                                           autoComplete="address-level3"/>
                                    <Trans i18nKey='helperStreetName'>
                                        <span className="Register-FormField-Helper">Street</span>
                                    </Trans>
                                </div>
                                <div className="Register-FormField">
                                    <input type="text" className="Register-Input" name="streetNumber"
                                           defaultValue={value.streetNumber || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderStreetNumber")}
                                           autoComplete="address-level4"/>
                                    <Trans i18nKey='helperStreetNumber'>
                                        <span className="Register-FormField-Helper">Street no.</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row Register-Row--1-3">
                                <div className="Register-FormField">
                                    <input type="number" className="Register-Input" name="postalCode"
                                           defaultValue={value.postalCode || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderPostalCode")}
                                           autoComplete="postal-code"/>
                                    <Trans i18nKey='helperPostalCode'>
                                        <span className="Register-FormField-Helper">Postal code</span>
                                    </Trans>
                                </div>
                                <div className="Register-FormField Register-FormField--Next">
                                    <input type="text" className="Register-Input" name="city"
                                           defaultValue={value.city || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderCity")}
                                           autoComplete="address-level2"/>
                                    <Trans i18nKey='helperCity'>
                                        <span className="Register-FormField-Helper">City</span>
                                    </Trans>
                                    <button disabled={!valid} hidden={!valid} type="submit"
                                            className="Button Button--Next"/>
                                </div>
                            </div>
                        </div>
                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('5');
    }
}

class RegisterPersonalConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: typeof props.value === "object" ? props.value : {},
            valid: props.valid || false,
            error: props.error,
            datalist: countryList
        };
    }

    handleChange(e) {
        const {value} = this.state;

        let pointer = value;
        e.target.name.split('.').forEach((name, i, arr) => {
            if (i === arr.length - 1) {
                // last
                pointer[name] = e.target.value
            } else {
                if (!pointer.hasOwnProperty(name)) {
                    pointer[name] = {};
                }
                pointer = pointer[name]
            }
        });

        let filterOptions = countryList;
        if (e.target.name === "country") {
            // edited country

            const stringValue = e.target.value;

            filterOptions = countryList.filter(
                d => stringValue === "" || d.text.includes(stringValue.toUpperCase())
            );

            this.refs.datalist.classList.add('Register-Datalist--Active');

            if (filterOptions.length === 1 && filterOptions[0].text === stringValue.toUpperCase()) {
                value.country = filterOptions[0];
                //this.refs.selectCountry.value = value.name;
            }
        }

        const [valid, error] = this.props.onChange(value);

        this.setState({value: value, valid: valid, datalist: filterOptions, error});
    }

    handleSelect(e) {
        if (e.target.nodeName.toLocaleLowerCase() === "li") {
            const {value} = this.state;
            const code = e.target.getAttribute("data-code");

            if (countries.hasOwnProperty(code)) {
                const country = countries[code];
                this.refs.selectCountry.value = country.name;
                this.refs.datalist.classList.remove('Register-Datalist--Active');

                value.country = country;

                const [valid, error] = this.props.onChange(value);
                this.setState({value, datalist: countryList, valid, error});
            }
        }
    }

    handleDateChange(e) {
        let {value} = this.state, personalDateOfBirth = value.personalDateOfBirth;
        personalDateOfBirth = typeof personalDateOfBirth === "object" ? personalDateOfBirth : {};

        const stringValue = e.target.value, name = e.target.name;

        switch (name) {
            case "dayOfMonth":
                personalDateOfBirth["dayOfMonth"] = stringValue;
                if (stringValue.length === 2) {
                    this.refs.dateMonth.focus();
                }
                break;
            case "month":
                personalDateOfBirth["month"] = stringValue;
                if (stringValue.length === 2) {
                    this.refs.dateYear.focus();
                }
                break;
            case "year":
                personalDateOfBirth["year"] = stringValue;
                break;
        }

        value.personalDateOfBirth = personalDateOfBirth;

        const [valid, error] = this.props.onChange(value);
        this.setState({value: value, valid: valid, error});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid, value} = this.state;

        if (valid) {
            this.setState({fetching: true});

            fetch(API_URL + '/existsCustomerByUsername?username=' + encodeURIComponent(value.email), {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                },
            }).then(res => {
                return res.json();
            }).then(json => {
                if (typeof json === "boolean") {
                    // ok
                    if (json) {
                        // email already exists
                        this.refs.error.textContent = window.trans("email-taken");
                        this.setState({error: {"email": window.trans("email-taken")}, fetching: false});
                    } else {
                        //this.refs.error.textContent = "";
                        //this.setState({error: null, fetching: false});


                        fetch(API_URL + '/isRegionValid?regionOrState=' + encodeURIComponent(value.country.name), {
                            method: 'get',
                            headers: {
                                'Accept': 'application/json',
                            },
                        }).then(res => {
                            return res.json();
                        }).then(json => {
                            if (typeof json === "boolean") {
                                // ok

                                value.country.valid = json;

                                const [valid, error] = this.props.onChange(value);

                                this.refs.error.textContent = "";
                                this.setState({value, valid, error, fetching: false});

                                this.props.onForward()
                            }
                        }).catch(err => {
                            this.setState({error: window.trans("something-wrong"), fetching: false});
                            console.error(err)
                        });
                        //this.props.onForward()
                    }
                }
            }).catch(err => {
                this.refs.error.textContent = window.trans("something-wrong");
                this.setState({error: null, fetching: false});
                console.error(err)
            });


            //this.props.onForward()
        } else {
            this.props.onForward() // go back to fix any errors
        }
    }

    render() {
        const {value, valid, datalist, error, fetching} = this.state;
        let errorMap = {};

        if (typeof error === "object") {
            if (error instanceof Array && error.length >= 4 && typeof error[0] === "object" &&
                error[0].hasOwnProperty("fieldName")) {

                if (error[3] instanceof Array) {
                    for (let i = 0; i < error[3].length; i++) {
                        if (typeof error[3][i] === "object") {
                            for (let key in error[3][i]) {
                                if (error[3][i].hasOwnProperty(key)) {
                                    errorMap[error[0].fieldName + "." + key] = error[3][i][key]
                                }
                            }
                        } else if (typeof error[3][i] === "string") {
                            errorMap[error[0].fieldName + "." + error[3][i]] = "required"
                        }
                    }
                } else if (typeof error[3] === "string") {
                    errorMap[error[0].fieldName] = error[3]
                } else if (error[3] === false) {
                    errorMap[error[0].fieldName] = "required"
                }
            } else if (error && typeof error === "object") {
                errorMap = error
            }
        }

        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={1} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingConfirmData'>Is your personal data correct,</Trans>
                                <b> {this.props.data.personalFirstName}?</b>
                            </h1>
                            <Trans i18nKey='subtitleConfirmData'>
                                <span
                                    className="Register-Subtitle">To finalize registration please confirm your data</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body Register-Content-Body--With-Action">
                            <div className="Register-Row Register-Row--1-1">
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("accountType")}>
                                    <select
                                        className={value.accountType ? "Register-Select with-value" : "Register-Select"}
                                        name="accountType" ref="accountType"
                                        defaultValue={value.accountType || "personal"}
                                        autoComplete="off"
                                        onChange={this.handleChange.bind(this)}>
                                        <Trans i18nKey='accountTypePersonal'>
                                            <option value="personal">Personal</option>
                                        </Trans>
                                        <Trans i18nKey='accountTypeBusiness'>
                                            <option value="business">Business</option>
                                        </Trans>
                                    </select>
                                    <Trans i18nKey='helperAccountType'>
                                        <span className="Register-FormField-Helper">Account type</span>
                                    </Trans>
                                </div>
                                <div className="Register-FormField" data-error={!!errorMap.hasOwnProperty("country")}>
                                    <div ref="datalist" className="Register-Datalist">
                                        <input type="text" className="Register-Datalist-Input Register-Input"
                                               ref="selectCountry" name="country"
                                            /*autoComplete="country-name"*/
                                               autoComplete="asdas"
                                               onChange={this.handleChange.bind(this)}
                                               defaultValue={value.country.name || ''}/>
                                        <ul className="Register-Datalist-Dropdown"
                                            onClick={this.handleSelect.bind(this)} aria-hidden="true">
                                            {datalist.map(c => (
                                                <li key={c.code} data-code={c.code}>{c.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <button className="Register-Select-Icon"/>
                                    <Trans i18nKey='helperCountry'>
                                        <span className="Register-FormField-Helper">Country</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row Register-Row--1-1">
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("personalFirstName")}>
                                    <input type="text" className="Register-Input" name="personalFirstName"
                                           defaultValue={value.personalFirstName || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderFirstName")}
                                           autoComplete="off"/>
                                    <Trans i18nKey='helperFirstName'>
                                        <span className="Register-FormField-Helper">First name</span>
                                    </Trans>
                                </div>
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("personalLastName")}>
                                    <input type="text" className="Register-Input" name="personalLastName"
                                           defaultValue={value.personalLastName || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderLastName")}
                                           autoComplete="off"/>
                                    <Trans i18nKey='helperLastName'>
                                        <span className="Register-FormField-Helper">Last name</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Date"
                                     data-error={!!errorMap.hasOwnProperty("personalDateOfBirth")}>
                                    <input type="text" data-language={i18next.language || 'en'}
                                           className="Register-FormField__Datepicker"
                                           ref="datepicker" aria-hidden="true"/>
                                    <input type="text" ref="dateDay"
                                           className="Register-Input Register-Input-PX-Input" name="dayOfMonth"
                                           onChange={this.handleDateChange.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"
                                           placeholder={window.trans("placeholderDateDay")}/>
                                    <input type="text" ref="dateMonth"
                                           className="Register-Input" name="month"
                                           onChange={this.handleDateChange.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"
                                           placeholder={window.trans("placeholderDateMonth")}/>
                                    <input type="text" ref="dateYear"
                                           className="Register-Input" name="year"
                                           onChange={this.handleDateChange.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"
                                           placeholder={window.trans("placeholderDateYear")}/>
                                    <span ref="showDatepicker" className="Register-Input-PX Register-Input-PX--Date"/>
                                    <Trans i18nKey='helperDateOfBirth'>
                                        <span className="Register-FormField-Helper">Date of birth</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row">
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("email")}>
                                    <input type="email" className="Register-Input" name="email"
                                           defaultValue={value.email || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderEmail")}
                                           autoComplete="off"/>
                                    <Trans i18nKey='helperEmail'>
                                        <span className="Register-FormField-Helper">Email address</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row Register-Row--3-1">
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("personalAddress.streetName")}>
                                    <input type="text" className="Register-Input" name="personalAddress.streetName"
                                           defaultValue={value.personalAddress.streetName || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderStreetAddress")}
                                           autoComplete="off"/>
                                    <Trans i18nKey='helperStreetName'>
                                        <span className="Register-FormField-Helper">Street name</span>
                                    </Trans>
                                </div>
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("personalAddress.streetNumber")}>
                                    <input type="text" className="Register-Input" name="personalAddress.streetNumber"
                                           defaultValue={value.personalAddress.streetNumber || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderStreetAddress")}
                                           autoComplete="off"/>
                                    <Trans i18nKey='helperStreetNumber'>
                                        <span className="Register-FormField-Helper">No</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row Register-Row--1-3">
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("personalAddress.postalCode")}>
                                    <input type="number" className="Register-Input" name="personalAddress.postalCode"
                                           defaultValue={value.personalAddress.postalCode || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderPostalCode")}
                                           autoComplete="off"/>
                                    <Trans i18nKey='helperPostalCode'>
                                        <span className="Register-FormField-Helper">Postal code</span>
                                    </Trans>
                                </div>
                                <div className="Register-FormField"
                                     data-error={!!errorMap.hasOwnProperty("personalAddress.city")}>
                                    <input type="text" className="Register-Input" name="personalAddress.city"
                                           defaultValue={value.personalAddress.city || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderCity")}
                                           autoComplete="off"/>
                                    <Trans i18nKey='helperCity'>
                                        <span className="Register-FormField-Helper">City</span>
                                    </Trans>
                                </div>

                            </div>
                            <div className="Register-Row">
                                <span ref="error"
                                      className="Register-FormField-Helper Register-FormField-Helper--Error"/>
                            </div>
                            <button disabled={fetching} type="submit"
                                    className="Button Button--Wide Register-Action"
                                    data-loading={fetching}>
                                <span className="Button__Label">
                                    <Trans i18nKey='confirm'>I confirm</Trans>
                                </span>
                                <span className="Button__Loader"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('6');

        // Datepicker

        this.$el = $(this.refs.datepicker);
        this.$el.datepicker({
            onSelect: (fd, d, picker) => {
                const dayString = d.getDate().toString(), monthString = (d.getMonth() + 1).toString(),
                    dayOfMonth = dayString.length === 1 ? '0' + dayString : dayString,
                    month = monthString.length === 1 ? '0' + monthString : monthString,
                    year = d.getFullYear().toString();

                this.refs.dateDay.value = dayOfMonth;
                this.refs.dateMonth.value = month;
                this.refs.dateYear.value = year;

                const {value} = this.state;
                value.personalDateOfBirth = {dayOfMonth, month, year};

                const [valid, err] = this.props.onChange(value);
                this.setState({value: value, valid: valid, error: window.trans(err)});
            }
        });
        const picker = this.$el.datepicker().data('datepicker');
        const {value} = this.state;
        if (!!value.personalDateOfBirth && typeof value.personalDateOfBirth === "object" &&
            value.personalDateOfBirth.hasOwnProperty("date") && value.personalDateOfBirth.date instanceof Date) {
            picker.selectDate(value.personalDateOfBirth.date);
        }
        this.refs.showDatepicker.addEventListener('click', () => {
            picker.show();
        });

        // Account type select

        const $el = $(this.refs.accountType).selectric({
            arrowButtonMarkup: '<button class="Register-Select-Icon"/>',
            onChange: e => {
                const {value} = this.state;

                value.accountType = $el.val();

                const [valid, err] = this.props.onChange(value);

                this.setState({value: value, valid: valid});
            }
        });

        // Country select

        this.refs.selectCountry.addEventListener('click', e => {
            this.refs.datalist.classList.toggle('Register-Datalist--Active');
        });
    }
}

class RegisterPhone extends Component {
    constructor(props) {
        super(props);

        const c = props.instance.data.value.country, tel_code = c && c.hasOwnProperty("tel_code") ? c.tel_code : '',
            value = typeof props.value === "object" ? props.value : {};

        value["phoneCode"] = value["phoneCode"] || tel_code || '';

        const [valid, err] = props.onChange(value);

        this.state = {
            phoneCode: value["phoneCode"],
            phoneNumber: value["phoneNumber"] || '',
            valid: valid,
            country: c
        };
    }

    handleChange(e) {
        const {phoneCode, phoneNumber} = this.state, value = {phoneCode, phoneNumber};

        if (e.target.name === "phoneCode") {
            if (e.target.value) {
                value[e.target.name] = e.target.value;
            } else {
                return
            }
        } else {
            value[e.target.name] = e.target.value;
        }

        const [valid, err] = this.props.onChange(value);

        this.setState({[e.target.name]: e.target.value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid, phoneCode, phoneNumber} = this.state;

        if (valid) {
            this.setState({fetching: true});

            fetch(API_URL + '/phone_verify?phone=' + encodeURIComponent(phoneCode + phoneNumber), {
                method: 'get',
                headers: {
                    "Accept": "application/json",
                },
            }).then(res => {
                return res.json();
            }).then(res => {
                // {Id: "5d079a9f5c40450001da6e1f"}

                if (res && typeof res === "object" && res.hasOwnProperty("Id")) {
                    const verId = res.Id;
                    const [valid, err] = this.props.onChange({
                        phoneCode,
                        phoneNumber,
                        verId
                    });

                    this.setState({
                        error: valid ? '' : 'something went wrong',
                        fetching: false,
                        valid: valid
                    });

                    if (valid) {
                        this.props.onForward();
                    }
                    return
                }

                this.setState({
                    error: 'something went wrong',
                    fetching: false
                });
            }).catch(err => {
                // TODO:
                this.setState({error: err.message, fetching: false});
                console.error(err)
            });
        }
    }

    render() {
        const {phoneCode, phoneNumber, valid, country, fetching} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={1} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingPhoneNumber'>What is your phone number,</Trans>
                                <b> {this.props.data.personalFirstName}?</b>
                            </h1>
                            <Trans i18nKey='subtitlePhoneNumber'>
                                <span className="Register-Subtitle">We will send you a 6-digit confirmation code to your phone to secure your account.</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row Register-Row--1-3">
                                <div className="Register-FormField">
                                    <select
                                        className={country ? "Register-Select with-value" : "Register-Select"}
                                        ref="selectCountryCode" name="phoneCode"
                                        autoComplete="tel-country-code"
                                        defaultValue={phoneCode || (country && country.tel_code ? country.tel_code || '' : '')}
                                        onChange={this.handleChange.bind(this)}>
                                        <Trans i18nKey='placeholderPhoneCode'>
                                            <option value="" disabled hidden>e.g. +386</option>
                                        </Trans>
                                        {Object.keys(countries).map((code, i) => {
                                            const c = countries[code];
                                            return <option key={code} value={c.tel_code}>{c.tel_code}</option>
                                        })}
                                    </select>
                                    {/*<Link className="Register-Select-Icon Register-Next"
                                          to="/register/personal/address"/>*/}
                                </div>
                                <div className="Register-FormField Register-FormField--Next">
                                    <input type="number" className="Register-Input" name="phoneNumber"
                                           defaultValue={phoneNumber || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderPhoneNumber")}
                                           autoComplete="tel-national"/>
                                    <button disabled={!valid || fetching} hidden={!valid} type="submit"
                                            data-loading={fetching}
                                            className="Button Button--Next">
                                        <div className="Button__Loader"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('7');

        this.$el = $(this.refs.selectCountryCode).selectric({
            arrowButtonMarkup: '<button class="Register-Select-Icon"/>',
            onChange: e => {
                const {phoneNumber} = this.state;
                const phoneCode = this.$el.val() || '';

                const value = {phoneCode, phoneNumber};

                const [valid, err] = this.props.onChange(value);

                this.setState({phoneCode, valid});
            }
        });
    }
}

class RegisterConfirmPhone extends Component {
    constructor(props) {
        super(props);
        this.phone = props.data.phone;
        this.state = {value: /*props.value ||*/ '', valid: /*props.valid ||*/ false};
    }

    handleChange(e) {
        const value = e.target.value || '', length = value.length;

        for (let i = 0; i < 6; i++) {
            this.refs["code" + i.toString()].value = value.charAt(i)
        }

        if (length === 6) {
            this.confirm(value)
        }
    }

    handleFocus(e) {
        const codeLength = this.refs.code.value.length;
        const focusPosition = codeLength < 6 && codeLength >= 0 ? codeLength : codeLength >= 6 ? 5 : 0;
        this.refs["code" + focusPosition.toString()].focus();
    }

    handleSingleChange(e) {
        const value = e.target.value || '', name = e.target.name, length = value.length;

        if (name === "code0" && length === 6) {
            // can accept whole code

            for (let i = 0; i < 6; i++) {
                this.refs["code" + i.toString()].value = value.charAt(i)
            }

            this.refs.code.value = value;
            this.confirm(value);
            return
        }

        let wholeValue = '';
        for (let i = 0; i < 6; i++) {
            wholeValue += this.refs["code" + i.toString()].value
        }

        this.refs.code.value = wholeValue;
        if (wholeValue.length === 6) {
            this.confirm(wholeValue)
        }

        if (length === 1) {
            if (name !== "code5") {
                e.target.nextElementSibling.focus();
            }
        } else if (e.target.valueLengthBeforeKey > length) {
            if (name !== "code0") {
                e.target.previousElementSibling.focus();
            }
        }
    }

    handleSingleKey(e) {
        const value = e.target.value || '', name = e.target.name;

        e.target.valueLengthBeforeKey = value.length;

        if (e.keyCode === 8 || e.keyCode === 46) {
            e.target.value = '';
            if (name !== "code0") {
                e.target.previousElementSibling.focus();
            }
        }
    }

    confirm(value) {
        this.refs.code.value = '';

        if (!value || value.length !== 6) {
            this.setState({error: window.trans('phone-confirmation-code-invalid'), fetching: false});
            return
        }

        this.setState({fetching: true});

        fetch(API_URL + '/phone_confirm', {
            method: 'post',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "verificationId": this.phone.verId,
                "code": value,
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            // {verified: true, Id: "5d088cc75c40450001da6e21"}

            const [valid, err] = this.props.onChange({verified: res.verified, verId: res.Id});

            if (valid) {
                this.refs.form.reset();
                this.refs.code0.focus();
                this.setState({error: '', fetching: false});
                this.props.onForward()
            } else {
                this.refs.form.reset();
                this.refs.code0.focus();
                if (res && typeof res === "object" && res.hasOwnProperty("message")) {
                    if (res.message === "Token expired") {
                        this.setState({error: window.trans('phone-confirmation-expired'), fetching: false});
                    }
                } else {
                    this.setState({error: window.trans('phone-confirmation-failed'), fetching: false});
                }
            }

        }).catch(err => {
            this.refs.form.reset();
            this.refs.code0.focus();
            this.setState({error: window.trans('phone-confirmation-failed'), fetching: false});
            console.log(err);
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.confirm(this.refs.code.value)
    }

    handleResend() {
        this.setState({resending: true});

        const {phoneCode, phoneNumber} = this.phone;
        const encodedNumber = encodeURIComponent(phoneCode + phoneNumber)

        fetch(API_URL + `/phone_verify?phone=${encodedNumber}&resend=true`, {
            method: 'get',
            headers: {
                "Accept": "application/json",
            },
        }).then(res => {
            return res.json();
        }).then(res => {
            // {Id: "5d079a9f5c40450001da6e1f"}

            if (res && typeof res === "object" && res.hasOwnProperty("Id")) {
                this.phone.verId = res.Id;
                this.setState({resending: false});
                return
            }

            this.setState({
                error: window.trans("something-wrong"),
                resending: false
            });
        }).catch(err => {
            this.setState({
                error: window.trans("something-wrong"),
                resending: false
            });
        });
    }

    render() {
        const {error, fetching, resending} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={1} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)} ref="form">
                        <div className="Register-Content-Header">
                            <Trans i18nKey='headingVerificationCode'>
                                <h1 className="Register-Heading2">Type your verification <b>code</b>.</h1>
                            </Trans>
                            <Trans i18nKey='subtitleVerificationCode'>
                                <span
                                    className="Register-Subtitle">We've sent you the verification code.</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body Register-Content-Body--With-Action">
                            <div className="Register-Row">
                                <div className="Register-EditField">
                                    <span
                                        className="Register-EditField-Value">{this.props.data.phone.phoneCode} {this.props.data.phone.phoneNumber}</span>
                                    <Link className="Register-EditField-Icon" to="/register/phone"/>
                                </div>
                            </div>
                            <div className="Register-Row-Spacing"/>
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Code">
                                    <input maxLength="6" type="text" ref="code0" name="code0"
                                           className="Register-Input Register-Input--Code"
                                           disabled={fetching}
                                           onChange={this.handleSingleChange.bind(this)}
                                           onKeyDown={this.handleSingleKey.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"/>
                                    <input maxLength="1" type="text" ref="code1" name="code1"
                                           className="Register-Input Register-Input--Code"
                                           disabled={fetching}
                                           onChange={this.handleSingleChange.bind(this)}
                                           onKeyDown={this.handleSingleKey.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"/>
                                    <input maxLength="1" type="text" ref="code2" name="code2"
                                           className="Register-Input Register-Input--Code"
                                           disabled={fetching}
                                           onChange={this.handleSingleChange.bind(this)}
                                           onKeyDown={this.handleSingleKey.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"/>
                                    <input maxLength="1" type="text" ref="code3" name="code3"
                                           className="Register-Input Register-Input--Code"
                                           disabled={fetching}
                                           onChange={this.handleSingleChange.bind(this)}
                                           onKeyDown={this.handleSingleKey.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"/>
                                    <input maxLength="1" type="text" ref="code4" name="code4"
                                           className="Register-Input Register-Input--Code"
                                           disabled={fetching}
                                           onChange={this.handleSingleChange.bind(this)}
                                           onKeyDown={this.handleSingleKey.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"/>
                                    <input maxLength="1" type="text" ref="code5" name="code5"
                                           className="Register-Input Register-Input--Code"
                                           disabled={fetching}
                                           onChange={this.handleSingleChange.bind(this)}
                                           onKeyDown={this.handleSingleKey.bind(this)}
                                           onFocus={e => e.target.select()}
                                           autoComplete="off"/>
                                    <input maxLength="6" type="text" ref="code" name="code"
                                           disabled={fetching}
                                           onChange={this.handleChange.bind(this)}
                                           onFocus={this.handleFocus.bind(this)}
                                           className="Register-Input Register-Input--Code-Master"
                                           autoComplete="one-time-code"/>
                                </div>
                                <span
                                    className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                            </div>

                            <div className="Register-Row-Spacing"/>
                            <button disabled={resending} onClick={this.handleResend.bind(this)}
                                    className="Button Button--Wide Register-Action"
                                    data-loading={resending}>
                                <span className="Button__Label">
                                    <Trans i18nKey='sendAgain'>Send again</Trans>
                                </span>
                                <span className="Button__Loader"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this);
        if (node instanceof HTMLElement) {
            const child = node.querySelector('input');
            child.addEventListener('keyup', e => {
                child.scrollLeft = 0;
            });
            child.addEventListener('keydown', e => {
                if (isNaN(e.key)) {
                    e.preventDefault();
                    return false
                }
                return true
            });
            child.addEventListener('keypress', e => {
                child.scrollLeft = 0;
                if (child.value.length >= 5) {
                    child.value += e.key;
                    child.blur();
                    child.disabled = true;
                    this.handleChange(e);
                    return false;
                }
            });
        }
        window.updateStep('8');
    }
}

class RegisterChoosePassword extends Component {
    constructor(props) {
        super(props);
        const value = typeof props.value === "object" ? props.value : {};
        this.state = {value: value, valid: props.valid || false, strength: this.strength(value)};
    }

    handleChange(e) {
        const {value} = this.state;

        value[e.target.name] = e.target.value;

        if (value["passwordRepeat"] && value["passwordRepeat"].length > 0 && value["passwordRepeat"] !== value["password"]) {
            this._psr = true;

            this.refs.passwordRepeat.classList.add("Register-Input--Invalid");
            this.refs.passwordRepeatError.textContent = window.trans("passwordDoesNotMatch");
        } else if (this._psr) {
            this.refs.passwordRepeat.classList.remove("Register-Input--Invalid");
            this.refs.passwordRepeatError.textContent = " ";
        }
        this.refs.passwordRepeatError.classList.remove("Register-FormField-Helper--Dropdown");
        this._stateWeak = false;

        const strength = this.strength(value);
        this.refs.password.classList.remove("Register-Input--Invalid");
        this.refs.password.classList.remove("Register-Input--Warning");
        this.refs.password.classList.remove("Register-Input--Success");
        this.refs.password.classList.add("Register-Input--" + strength.class);


        const [valid, err] = this.props.onChange(value);
        this.setState({value: value, valid: valid, strength: strength});
    }

    strength(value) {
        let strength;
        let password = value["password"] || '';
        strength = window.zxcvbn(password);
        if (password.length < 8) {
            strength.text = window.trans("passwordTooShort");
            strength.class = "Invalid";
        } else if (strength.score <= 0) {
            strength.text = window.trans("passwordUnsafe");
            strength.class = "Invalid";
        } else if (strength.score <= 1) {
            strength.text = window.trans("passwordWeak");
            strength.class = "Warning";
        } else if (strength.score <= 2) {
            strength.text = window.trans("passwordGood");
            strength.class = "Success";
        } else if (strength.score <= 3) {
            strength.text = window.trans("passwordStrong");
            strength.class = "Success";
        } else {
            strength.text = window.trans("passwordVeryStrong");
            strength.class = "Success";
        }
        return strength
    }

    handleSubmit(e) {
        e.preventDefault();
        const {value, valid} = this.state;
        const strength = this.strength(value);

        if (strength.score <= 1) {
            if (!this._stateWeak) {
                this.refs.passwordRepeatError.classList.add("Register-FormField-Helper--Dropdown");
                this.refs.passwordRepeatError.textContent = window.trans("weakPasswordConfirmation");
                this._stateWeak = true;
                return;
            }
        } else {
            this.refs.passwordRepeatError.classList.remove("Register-FormField-Helper--Dropdown");
            this.refs.passwordRepeatError.textContent = "";
        }

        if (valid) {
            this.props.onForward()
        }
    }

    render() {
        const {value, valid, strength} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={2} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <input hidden aria-hidden="true" type="email" autoComplete="username"
                               defaultValue={this.props.data.email || ""}/>

                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingPassword'>Select your password,</Trans>
                                <b> {this.props.data.personalFirstName}!</b>
                            </h1>
                        </div>
                        <div className="Register-Content-Body">
                            <div className="Register-Row">
                                <div className="Register-FormField">
                                    <input name="password" ref="password" type="password" className="Register-Input"
                                           defaultValue={value.password || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderPassword")}
                                           autoComplete="new-password"/>
                                    <i onClick={this.togglePasswordVisibility.bind(this)}
                                       className="Register-Select-Icon Register-Select-Icon--Show"/>
                                </div>
                            </div>
                            {/*<div className="Register-Content-Spacer"/>*/}
                            <div className="Register-Row Register-Row--No-Margin-Top">
                                <div className="Register-Password-Meter">
                                    <Trans i18nKey='passwordStrength'>Password strength: </Trans><span
                                    className="Register-Password-Meter-State">{strength.text}</span>
                                </div>
                            </div>
                            <div className="Register-Row Register-Row--No-Margin-Top">
                                <Trans i18nKey='passwordHint'>
                                    <ul className="Register-Hint">
                                        <li>Use at least 8 characters.</li>
                                        <li>Besides letters, include at least a number or symbol.</li>
                                        <li>Don't use a password from another site, or something too obvious like your
                                            pet's name.
                                        </li>
                                    </ul>
                                </Trans>
                            </div>
                            <div className="Register-Content-Spacer"/>
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Next">
                                    <input name="passwordRepeat" ref="passwordRepeat" type="password"
                                           className="Register-Input"
                                           defaultValue={value.passwordRepeat || ""}
                                           onChange={this.handleChange.bind(this)}
                                           placeholder={window.trans("placeholderRepeatPassword")}
                                           autoComplete="new-password"/>
                                    <i onClick={this.togglePasswordVisibility.bind(this)}
                                       className="Register-Select-Icon Register-Select-Icon--Show"/>
                                    <button disabled={!valid} hidden={!valid} type="submit"
                                            className="Button Button--Next"/>
                                    <span ref="passwordRepeatError"
                                          className="Register-FormField-Helper Register-FormField-Helper--Error">&nbsp;</span>
                                </div>
                            </div>
                        </div>

                        <RegisterConnect/>
                        <RegisterLoginInstead/>
                    </form>
                </div>
            </div>
        );
    }

    togglePasswordVisibility(e) {
        const isHidden = e.target.classList.contains('Register-Select-Icon--Show');
        e.target.previousSibling.type = isHidden ? "text" : "password";
        e.target.classList.toggle('Register-Select-Icon--Show');
        e.target.classList.toggle('Register-Select-Icon--Hide');
    }

    componentDidMount() {
        window.updateStep('9');
    }
}

class TOS extends Component {
    constructor(props) {
        super(props);

        this.onClose = props.onClose;
    }

    close() {
        const node = ReactDOM.findDOMNode(this);
        if (node instanceof HTMLElement) {
            node.classList.remove('Register-Up--Visible')
        }
        if (this.onClose) {
            this.onClose()
        }
    }

    render() {
        return (
            <div id="TOS" className="Register-Up">
                <div className="Register-Up-Header">
                    <div className="Register-Up-Header-Content">
                        <Trans i18nKey='headingTOS'>
                            <span className="Register-Up-Header-Text">Nekster terms and conditions</span>
                        </Trans>
                        <span onClick={() => this.close()} className="Register-Up-Header-Close"/>
                    </div>
                </div>
                <div className="Register-Up-Content">
                    <div className="Register-Up-Content-Body">
                        <Terms />
                    </div>
                </div>
            </div>
        );
    }
}

class RegisterAgreements extends Component {
    el = null;

    openTOS() {
        const child = this.el.querySelector('#TOS');
        child.classList.add('Register-Up--Visible');
        this.refs.form.classList.add("hidden")
    }

    onCloseTOS() {
        this.refs.form.classList.remove("hidden")
    }

    constructor(props) {
        super(props);
        props.onChange({}); // delete agreements on refresh
        this.state = {value: {}, valid: false};
    }

    handleChange(e) {
        const {value} = this.state;

        value[e.target.name] = e.target.checked;

        const [valid, err] = this.props.onChange(value);

        this.setState({value: value, valid: valid});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {valid} = this.state;

        if (!valid) {
            this.setState({error: window.trans("agreementsRequired")});
            return
        }

        // Open account
        const {instance, validator} = this.props, country = instance.data.value.country,
            countryName = country ? country.name : '';

        const v = validator();
        const [step, _, isValid, err] = v.validate(steps["account-type"], steps["agreements"]);

        if (isValid) {
            this.setState({fetching: true});

            let data = {};
            const dateOfBirth = v.data.value.personalDateOfBirth;

            if (v.data.value.accountType === "personal") {
                data = {
                    "username": v.data.value.email,
                    "humanLastName": v.data.value.personalLastName,
                    "humanFirstName": v.data.value.personalFirstName,
                    "email": v.data.value.email,
                    "telephone": v.data.value.phone.phoneCode + v.data.value.phone.phoneNumber,
                    "dateOfBirth": {
                        "dayOfMonth": dateOfBirth.dayOfMonthInt,
                        "month": dateOfBirth.monthInt,
                        "year": dateOfBirth.yearInt,
                    },
                    "password": v.data.value.password.password,
                    "address": {
                        "unitNumber": null,
                        "streetName": v.data.value.personalAddress.streetName,
                        "streetNumber": v.data.value.personalAddress.streetNumber,
                        "townOrSuburb": v.data.value.personalAddress.city,
                        "regionOrState": countryName
                    },
                    "investorType": "Personal",
                    "phoneVerificationId": v.data.value.phoneConfirm.verId
                };

            } else {
                data = {
                    "username": v.data.value.email,
                    "humanLastName": v.data.value.personalLastName,
                    "humanFirstName": v.data.value.personalFirstName,
                    "email": v.data.value.email,
                    "telephone": v.data.value.phone.phoneCode + v.data.value.phone.phoneNumber,
                    "dateOfBirth": {
                        "dayOfMonth": dateOfBirth.dayOfMonthInt,
                        "month": dateOfBirth.monthInt,
                        "year": dateOfBirth.yearInt,
                    },
                    "password": v.data.value.password.password,
                    "address": {
                        "unitNumber": null,
                        "streetName": v.data.value.personalAddress.streetName,
                        "streetNumber": v.data.value.personalAddress.streetNumber,
                        "townOrSuburb": v.data.value.personalAddress.city,
                        "regionOrState": countryName
                    },
                    "investorType": "Business",
                    "company": v.data.value.company,
                    "phoneVerificationId": v.data.value.phoneConfirm.verId

                }
            }

            fetch(API_URL + "/newCustomerMinimal", {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                referrer: "no-referrer",
                body: JSON.stringify(data)
            }).then(res => {
                //console.log(res);
                if (res.ok) {
                    return res.json()
                } else {
                    return res.text()
                }
            }).then(json => {
                console.log(json);
                if (json && typeof json === "object") {
                    this.setState({fetching: false});
                    //console.log(json);
                    this.props.onForward();
                } else {
                    this.setState({fetching: false, error: window.trans('error-creating-account')});
                }
            }).catch(err => {
                this.setState({fetching: false, error: window.trans('error-creating-account')});
                console.error(err)
            })
        }
    }

    render() {
        const {fetching, valid, error} = this.state;
        return (
            <div className="Register-Desktop-Steps-Wrapper">
                <RegisterDesktopSteps step={3} userData={this.props.userData}/>
                <div className="Register-Desktop-Steps-Content">
                    <form onSubmit={this.handleSubmit.bind(this)} ref="form">
                        <div className="Register-Content-Header">
                            <h1 className="Register-Heading2">
                                <Trans i18nKey='headingAgreements'>We are almost done,</Trans>
                                <b> {this.props.data.personalFirstName}.</b>
                            </h1>
                            <Trans i18nKey='subtitleAgreements'>
                                <span className="Register-Subtitle">Let's finalize your account.</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body Register-Content-Body--With-Action">
                            <div className="Register-Content-Spacer"/>
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Horizontal">
                                    <label className="Register-Checkbox">
                                        <input name="tosAgreement" onChange={this.handleChange.bind(this)}
                                               type="checkbox"/>
                                        <div className="Register-Checkbox-Icon"/>
                                    </label>
                                    <Trans i18nKey='agreementsText1'>
                                    <span className="Register-Checkbox-Text">I hereby open my Nekster account with accordance to the following legal documents which I have read and to which I consent: <a
                                        onClick={() => this.openTOS()}>Nekster Terms and Conditions</a>; and I confirm that I act on my own behalf.</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Horizontal">
                                    <label className="Register-Checkbox">
                                        <input name="privacyAgreement" onChange={this.handleChange.bind(this)}
                                               type="checkbox"/>
                                        <div className="Register-Checkbox-Icon"/>
                                    </label>
                                    <Trans i18nKey='agreementsText2'>
                                        <span className="Register-Checkbox-Text">Our <a onClick={() => this.openTOS()}>Privacy policy</a> applies.</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row">
                                <div className="Register-FormField Register-FormField--Horizontal">
                                    <label className="Register-Checkbox">
                                        <input name="notPoliticallyExposed" onChange={this.handleChange.bind(this)}
                                               type="checkbox"/>
                                        <div className="Register-Checkbox-Icon"/>
                                    </label>
                                    <Trans i18nKey='agreementsText3'>
                                    <span
                                        className="Register-Checkbox-Text">I confirm that I am not <a
                                        onClick={() => this.openTOS()}>politically exposed person</a>.</span>
                                    </Trans>
                                </div>
                            </div>
                            <div className="Register-Row">
                                <span
                                    className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                            </div>

                            <button disabled={fetching} type="submit"
                                    data-loading={fetching}
                                    className="Button Button--Wide Register-Action">
                                <span className="Button__Label">
                                    <Trans i18nKey='openAccount'>Open my account</Trans>
                                </span>
                                <div className="Button__Loader"/>
                            </button>
                        </div>
                    </form>
                    <TOS onClose={this.onCloseTOS.bind(this)}/>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.el = ReactDOM.findDOMNode(this);
        window.updateStep('10');
    }
}

class RegisterConfirmEmail extends Component {
    state = {redirect: false};

    render() {
        let {redirect} = this.state;
        if (redirect) return <Redirect to="/register/final"/>;

        return (
            <div>
                <div className="Register-Content-Header">
                    <Trans i18nKey='headingConfirmEmail'>
                        <h1 className="Register-Heading2">Check your email <b>inbox</b>.</h1>
                    </Trans>
                    <Trans i18nKey='subtitleConfirmEmail'>
                        <span className="Register-Subtitle">We have sent you verification email to start your investing journey</span>
                    </Trans>
                </div>
                {/*<div className="Register-Content-Body Register-Content-Body--With-Action">
                    <Link to="/register/final" className="Button Button--Wide Register-Action">
                        <Trans i18nKey='sendAgain'>Send again</Trans>
                    </Link>
                </div>*/}
            </div>
        );
    }

    componentDidMount() {
        window.updateStep();
    }
}

class RegisterFinal extends Component {
    state = {redirect: false};

    render() {
        let {redirect} = this.state;
        if (redirect) return <Redirect to="/register/final"/>;

        return (
            <div className="Register-Desktop-Steps-Wrapper">
                {/*<RegisterDesktopSteps step={3} userData={this.props.userData}/>*/}
                <div className="Register-Desktop-Steps-Content">
                    <div>
                        <div className="Register-Content-Header">
                            <Trans i18nKey='headingFinal'>
                                <h1 className="Register-Heading2"><b>Congratulations!</b></h1>
                            </Trans>
                            <Trans i18nKey='headingFinal2'>
                                <h1 className="Register-Heading">Your account is now verified.</h1>
                            </Trans>
                            <Trans i18nKey='subtitleFinal'>
                                <span className="Register-Subtitle">Start using our platform by logging in</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body Register-Content-Body--With-Action">
                            <Link to="/" className="Button Button--Wide Register-Action">
                                <Trans i18nKey='loginNow'>Login now</Trans>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.updateStep('11');
    }
}

class UserData extends Component {
    constructor(props) {
        super(props);
        this.data = {
            timestamp: 0,
            value: {}
        };
        window.ud_ = this; // todo: remove in production
    }

    // clear registration data
    clear() {
        this.data = {
            timestamp: 0,
            value: {}
        };
        localStorage.removeItem('reg')
    }

    componentDidMount() {
        this.readFromLocalStorage();
        // add event listener to save state to localStorage
        // when user leaves/refreshes the page
        window.addEventListener(
            "beforeunload",
            this.saveToLocalStorage.bind(this)
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            "beforeunload",
            this.saveToLocalStorage.bind(this)
        );
        // saves if component has a chance to unmount
        this.saveToLocalStorage();
    }

    render() {
        return ""
    }

    // save registration data
    saveToLocalStorage() {
        this.data.timestamp = (new Date()).getTime();
        localStorage.setItem('reg', JSON.stringify(this.data))
    }

    // read registration data
    readFromLocalStorage() {
        let last;
        try {
            last = JSON.parse(localStorage.getItem('reg') || '{}');
        } catch (e) {
        }
        if (last && typeof last === "object" && last.hasOwnProperty('timestamp')) {
            const now = new Date();
            now.setHours(now.getHours() - 1);
            const timestamp = now.getTime();
            if (timestamp > 0 && last.timestamp > timestamp) {
                this.data.timestamp = last.timestamp;
                this.data.completed = -1;
                this.data.value = last.value || {};
                if (typeof this.data.value.personalDateOfBirth === "object" && typeof this.data.value.personalDateOfBirth.date === "string") {
                    this.data.value.personalDateOfBirth.date = new Date(this.data.value.personalDateOfBirth.date);
                }
            }
        }
        this.props.with.setState({dataReady: true, dataInstance: this})
    }

    // route handler
    routeHandler({match}) {
        let step;
        if (!steps.hasOwnProperty(match.params.step)) {
            //return (<NotFound />)
            return (<h2>Not found</h2>)
        } else {
            step = steps[match.params.step]
        }

        if (match.params.step2) {
            if (!step.hasOwnProperty(match.params.step2)) {
                return (<h2>Not found - param doesn't match</h2>)
            } else {
                step = step[match.params.step2]
            }
        }

        // validate previous values and go to step
        return this.goToStep(steps['account-type'], step);
    }

    goToStep(startStep, goalStep) {
        const [step, value, isValid, err] = this.validate(startStep, goalStep);

        window.history.replaceState(null, '', step.href);

        if (err === "not-found") {
            return (<h1>Not found</h1>)
        }

        const TagName = step.component;

        let _isValid = isValid, _value = value, _err = err !== true ? err : null;
        // render if reached goal step or if value is not valid
        return (
            <TagName data={this.data.value} userData={this.data} instance={this} value={_value}
                     onChange={(newValue) => {
                         _value = newValue;
                         _err = step.validate(_value, this);
                         _isValid = _err === true;
                         if (step.fieldName) {
                             this.data.value[step.fieldName] = _value;
                         } else {
                             this.data.value = _value;
                         }
                         return [_isValid, _err !== true ? _err : null]
                     }} valid={_isValid} error={_err} validator={() => {
                return this
            }} onForward={() => {
                let next;
                if (typeof step.next === "function") {
                    next = step.next(_value, value, this.data)
                }
                if (!next) {
                    return false
                }
                this.props.history.push(next.href);
                this.saveToLocalStorage(); // because onbeforeunload doesn't work for some mobile devices
                return true
            }}/>)
    }

    validate(start, end) {
        let value = start.fieldName ? this.data.value[start.fieldName] : this.data.value;
        let errorMsg = start.validate(value, this), isValid = errorMsg === true;

        if (start.href === end.href || !isValid) {
            // return if reached end or value is invalid
            return [start, value, isValid, errorMsg !== true ? errorMsg : null]
        }

        // continue towards "goal" step
        let next;
        if (typeof start.next === "function") {
            next = start.next(value, value, this.data)
        }

        if (next === false) {
            return [start, value, isValid, "not-found"]
        }

        if (start && start.hasOwnProperty("step") && (!this.data.completed || this.data.completed < start.step)) {
            // update number of completed steps (to update DesktopSteps)
            this.data.completed = start.step;
        }

        return this.validate(next, end)
    }
}

const steps = {
    'account-type': {
        href: '/register/account-type',
        component: RegisterStep2,
        fieldName: 'accountType',
        step: 0,
        next: (val, old, d) => {
            // clear all data on account type change
            if (old && old.length > 0 && val !== old) {
                d.value = {};
                d.value[steps["account-type"].fieldName] = val;
                d.completed = steps["account-type"].step;
            }
            switch (val) {
                case "personal": {
                    return steps["personal"]["country"];
                }
                case "business": {
                    return steps["business"]["country"];
                }
            }
            return false
        },
        validate: (val) => {
            return val === "personal" || val === "business"
        }
    },
    'business': {
        'country': {
            href: '/register/business/country',
            component: RegisterBusinessCountry,
            fieldName: 'country',
            step: 0,
            next: (value, __, d) => {
                if (typeof value === "object" && countries.hasOwnProperty(value.code) && !value.valid) {
                    return steps["country-not-supported"]
                }
                if (d.value.hasOwnProperty("accountType")) {
                    if (d.value.accountType === "personal") {
                        return steps["personal"]["first-name"]
                    } else if (d.value.accountType === "business") {
                        return steps["business"]["vat"]
                    }
                }
                return steps["account-type"]
            },
            validate: (value) => {
                if (typeof value === "object" && countries.hasOwnProperty(value.code)) {
                    //return country[value]["enabled"]
                    return true
                }
                return false
            }
        },
        'vat': {
            href: '/register/business/vat',
            component: RegisterBusinessVat,
            fieldName: 'businessVAT',
            next: () => {
                return steps["business"]["company"]
            },
            validate: (value, userData) => {
                if (typeof value !== "string") {
                    return "invalid-type"
                }
                if (value.length < 8) {
                    return "too-short"
                }
                const c = userData.data.value.country,
                    regex = c && c.hasOwnProperty("vat_regex") ? new RegExp(c.vat_regex) : false;
                if (!regex) {
                    return "regex-undefined"
                }
                return regex.test(value) || "invalid-format"
            }
        },
        'company': {
            href: '/register/business/company',
            component: RegisterBusinessCompany,
            fieldName: 'company',
            next: () => {
                return steps["business"]["legal"]
            },
            validate: (value) => {
                return true
            }
        },
        'legal': {
            href: '/register/business/legal',
            component: RegisterBusinessLegal,
            fieldName: 'businessLegal',
            next: (value) => {
                if (value !== "on") {
                    return steps["legal-representative-error"]
                }
                return steps["personal"]["first-name"]
            },
            validate: (value) => {
                return true
                //return value === "on" || "required"
            }
        },
    },
    'personal': {
        'country': {
            href: '/register/personal/country',
            component: RegisterPersonalCountry,
            fieldName: 'country',
            step: 0,
            next: (value, __, d) => {
                if (typeof value === "object" && countries.hasOwnProperty(value.code) && !value.valid) {
                    return steps["country-not-supported"]
                }
                if (d.value.hasOwnProperty("accountType")) {
                    if (d.value.accountType === "personal") {
                        return steps["personal"]["first-name"]
                    } else if (d.value.accountType === "business") {
                        return steps["business"]["vat"]
                    }
                }
                return steps["account-type"]
            },
            validate: (value) => {
                // all listed countries are accepted
                return typeof value === "object" && countries.hasOwnProperty(value.code) || "required"
            }
        },
        'first-name': {
            href: '/register/personal/first-name',
            component: RegisterPersonalFirstName,
            fieldName: 'personalFirstName',
            step: 0,
            next: () => {
                return steps["personal"]["last-name"]
            },
            validate: (value) => {
                return value && value.length > 1 || "required"
            }
        },
        'last-name': {
            href: '/register/personal/last-name',
            component: RegisterPersonalLastName,
            fieldName: 'personalLastName',
            step: 0,
            next: () => {
                return steps["personal"]["date-of-birth"]
            },
            validate: (value) => {
                return value && value.length > 1 || "required"
            }
        },
        'date-of-birth': {
            href: '/register/personal/date-of-birth',
            component: RegisterPersonalDateOfBirth,
            fieldName: 'personalDateOfBirth',
            step: 0,
            next: () => {
                return steps["personal"]["email"]
            },
            validate: (value) => {
                if (value && typeof value === "object" && value.hasOwnProperty("dayOfMonth")
                    && value.hasOwnProperty("month")
                    && value.hasOwnProperty("year")) {

                    const date = new Date(), legalAge = new Date(), dayOfMonth = parseInt(value.dayOfMonth),
                        month = parseInt(value.month), year = parseInt(value.year);


                    if (!dayOfMonth || !month || !year) {
                        // error parsing or value is 0
                        return "required"
                    }

                    if (value.dayOfMonth.length > 2 || value.month.length > 2 || value.year.length !== 4) {
                        // date not in format DD MM YYYY
                        return "invalid-date-format"
                    }

                    date.setFullYear(year, month - 1, dayOfMonth); // set date

                    // save parsed int
                    value.dayOfMonthInt = dayOfMonth;
                    value.monthInt = month;
                    value.yearInt = year;
                    value.date = date;

                    if (date.getDate() !== dayOfMonth || date.getMonth() !== (month - 1) || date.getFullYear() !== year) {
                        // check if date entered is a valid calendar date
                        return "invalid-date"
                    }

                    // legal age check
                    legalAge.setUTCFullYear(legalAge.getUTCFullYear() - 18);
                    return date.getTime() < legalAge.getTime() || "legal-age"

                }
                return "required"
            }
        },
        'email': {
            href: '/register/personal/email',
            component: RegisterPersonalEmail,
            fieldName: 'email',
            step: 1,
            next: () => {
                return steps["personal"]["address"]
            },
            validate: (value) => {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(value)
            }
        },
        'address': {
            href: '/register/personal/address',
            component: RegisterPersonalAddress,
            fieldName: 'personalAddress',
            step: 1,
            next: () => {
                return steps["personal"]["confirm"]
            },
            validate: (value) => {
                const errArr = [];

                if (typeof value === "object") {
                    if (!value.hasOwnProperty("streetName") || value.streetName.length <= 1) {
                        errArr.push({"streetName": "required"})
                    }
                    if (!value.hasOwnProperty("streetNumber") || value.streetNumber.length < 1) {
                        errArr.push({"streetNumber": "required"})
                    }
                    if (!value.hasOwnProperty("postalCode") || value.postalCode.length <= 1) {
                        errArr.push({"postalCode": "required"})
                    }
                    if (!value.hasOwnProperty("city") || value.city.length <= 1) {
                        errArr.push({"city": "required"})
                    }

                    if (errArr.length === 0) {
                        return true
                    }
                }

                return errArr
            }
        },
        'confirm': {
            href: '/register/personal/confirm',
            component: RegisterPersonalConfirm,
            step: 1,
            next: () => {
                return steps["phone"]
            },
            validate: (value, userData) => {
                const valid = userData.validate(steps['account-type'], steps['personal']['address']);
                return valid[2] === true ? true : valid
                //return userData.validate(steps['account-type'], steps['personal']['address']);
            }
        }
    },
    'country-not-supported': {
        href: '/register/country-not-supported',
        component: RegisterBusinessCountryNotSupported,
        step: 0,
        next: (_, __, d) => {
            return steps["account-type"]
        },
        validate: (value) => {
            return false
        }
    },
    'legal-representative-error': {
        href: '/register/legal-representative-error',
        component: RegisterBusinessLegalRepresentativeError,
        step: 0,
        next: (_, __, d) => {
            return steps["account-type"]
        },
        validate: (value) => {
            return false
        }
    },
    'phone': {
        href: '/register/phone',
        component: RegisterPhone,
        fieldName: 'phone',
        step: 1,
        next: () => {
            return steps["confirm-phone"]
        },
        validate: (value) => {
            if (typeof value === "object" && value.hasOwnProperty("phoneNumber")
                && value.hasOwnProperty("phoneCode")) {
                return (value.phoneNumber.length > 0 && value.phoneCode.length > 0) || "required"
            }
            return "required"
        }
    },
    'confirm-phone': {
        href: '/register/confirm-phone',
        component: RegisterConfirmPhone,
        fieldName: 'phoneConfirm',
        step: 1,
        next: () => {
            return steps["choose-password"]
        },
        validate: (value, userData) => {
            if (typeof value !== "object") {
                return "requred"
            }

            if (userData.data.value.phone.verId !== value.verId) {
                return "no-match"
            }

            return value.verified === true
        }
    },
    'choose-password': {
        href: '/register/choose-password',
        component: RegisterChoosePassword,
        fieldName: 'password',
        step: 2,
        next: () => {
            return steps["agreements"]
        },
        validate: (value) => {
            if (typeof value === "object" && value.hasOwnProperty("password")
                && value.hasOwnProperty("passwordRepeat")) {
                if (value.password.length < 8) {
                    return "too-short"
                }
                if (value.password !== value.passwordRepeat) {
                    return "not-matching"
                }
                if (window.zxcvbn(value.password).score < 1) {
                    return "low-score"
                }
                return true
            }
            return "required"
        }
    },
    'agreements': {
        href: '/register/agreements',
        component: RegisterAgreements,
        fieldName: 'agreements',
        step: 3,
        next: () => {
            return steps["confirm-email"]
        },
        validate: (value) => {
            if (typeof value === "object" && value.hasOwnProperty("tosAgreement")
                && value.hasOwnProperty("privacyAgreement")
                && value.hasOwnProperty("notPoliticallyExposed")) {
                return value.tosAgreement === true && value.privacyAgreement === true && value.notPoliticallyExposed === true
            }
            return "required"
        }
    },
    'confirm-email': {
        href: '/register/confirm-email',
        component: RegisterConfirmEmail,
        fieldName: 'emailConfirmed',
        next: () => {
            return steps["final"]
        },
        validate: (value) => {
            return true
        }
    },
    'final': {
        href: '/register/final',
        component: RegisterFinal,
        step: 3,
        validate: () => {
            // check if email is confirmed

            return true
        }
    }
};

const UserDataWRouter = withRouter(UserData);


Login = connect(mapStateToProps, mapDispatchToProps)(withTranslation("register")(Login));

class Register extends Component {
    state = {};

    constructor(props) {
        super(props);

        window.trans = props.t;
    }

    render() {
        const {scriptLoaded, dataReady, dataInstance} = this.state, {error} = this.props; // error coming from Root.js (SESSION_EXPIRED)
        return (
            <div className="Register">
                <Script
                    url="https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js"
                    /*onCreate={this.handleScriptCreate.bind(this)}
                    onError={this.handleScriptError.bind(this)}*/
                    onLoad={this.handleScriptLoad.bind(this)}
                />

                <Router>
                    <ScrollToTop>
                        <RegisterHeader/>
                        <RegisterContent>
                            <UserDataWRouter with={this}/>
                            {scriptLoaded && dataReady ?
                                <div className="Container Container--Stretch">
                                    <Switch>
                                        <Route path="/u/" render={() => (
                                            <Redirect to="/"/>
                                        )}/>
                                        <Route exact path="/" component={() => (
                                            <Login error={error}/>
                                        )}/>
                                        <Route exact path="/password-reset/:id" component={({match = {}} = {}) => (
                                            <AppResetPassword id={match.params.id}/>
                                        )}/>
                                        <Route exact path="/lost-password" component={() => (
                                            <AppLostPassword/>
                                        )}/>
                                        <Route exact path="/sent-confirmation" component={() => (
                                            <AppSentConfirmation/>
                                        )}/>
                                        <Route exact path="/register" component={RegisterStep1}/>
                                        <Route exact path="/registration/complete" component={RegisterFinal}/>
                                        <Route exact path="/register/:step"
                                               component={dataInstance.routeHandler.bind(dataInstance)}/>
                                        <Route exact path="/register/:step/:step2"
                                               component={dataInstance.routeHandler.bind(dataInstance)}/>
                                        {/*<Route exact path="/register/account-type" component={RegisterStep2}/>
                                <Route exact path="/register/personal/country" component={RegisterPersonalCountry}/>
                                <Route exact path="/register/personal/first-name"
                                       component={RegisterPersonalFirstName}/>
                                <Route exact path="/register/personal/last-name" component={RegisterPersonalLastName}/>
                                <Route exact path="/register/personal/date-of-birth"
                                       component={RegisterPersonalDateOfBirth}/>
                                <Route exact path="/register/personal/email" component={RegisterPersonalEmail}/>
                                <Route exact path="/register/personal/address" component={RegisterPersonalAddress}/>
                                <Route exact path="/register/personal/confirm" component={RegisterPersonalConfirm}/>
                                <Route exact path="/register/phone" component={RegisterPhone}/>
                                <Route exact path="/register/confirm-phone" component={RegisterConfirmPhone}/>
                                <Route exact path="/register/choose-password" component={RegisterChoosePassword}/>
                                <Route exact path="/register/personal/agreements"
                                       component={RegisterPersonalAgreements}/>
                                <Route exact path="/register/confirm-email" component={RegisterConfirmEmail}/>
                                <Route exact path="/register/final" component={RegisterFinal}/>*/}
                                        <Route component={NotFound}/>
                                    </Switch>
                                </div> : <div>Loading ...</div>}

                        </RegisterContent>
                        <RegisterFooter/>
                        <Analytics/>
                    </ScrollToTop>
                </Router>
            </div>
        );
    }

    handleScriptLoad() {
        this.setState({scriptLoaded: true});

    }
}


export default withTranslation('register')(Register);
